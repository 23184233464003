import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Image,
  HStack,
  Input,
  Box,
  Spinner,
  Center,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { TokenDto } from "../hooks/useTokensAPI";
import { useWhitelistedTokens } from "../pages/Tokens/useWhitelistedTokens";
import { displayPubkey } from "../utils/pubkey";

interface TokenPickerProps {
  onSelect(token: TokenDto): void;
  token?: TokenDto;
  otherToken?: TokenDto;
  disabled?: boolean;
}

export function TokenPicker({ onSelect, token, disabled = false, otherToken }: TokenPickerProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { tokens, reload: reloadTokens, handleFilter, loading } = useWhitelistedTokens();
  const [filter, setFilter] = useState<string>();

  // Reload the tokens everytime token picker mounts
  useEffect(() => {
    reloadTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleFilter(filter);
  }, [filter, handleFilter]);

  const tokensWithoutOtherToken = useMemo(
    () => tokens.filter((token) => token.mint !== otherToken?.mint),
    [tokens, otherToken]
  );

  return (
    <>
      <Button disabled={disabled} h="42px" onClick={onOpen}>
        {token ? (
          <HStack>
            <Image borderRadius="50px" w="30px" src={token.logoURI} />
            <Text>{token.symbol}</Text>
            <Text>({displayPubkey(token.mint)})</Text>
          </HStack>
        ) : (
          "Token Picker"
        )}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pick a Token</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter token symbol or mint"
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            />
            <Box h="10px" />
            {!loading ? (
              tokensWithoutOtherToken.map((token) => (
                // eslint-disable-next-line react/jsx-no-undef
                <HStack
                  onClick={() => {
                    setFilter(undefined);
                    onSelect(token);
                    onClose();
                  }}
                  borderRadius="10px"
                  cursor="pointer"
                  _hover={{
                    bgColor: "whiteAlpha.100",
                  }}
                  p="10px"
                  key={token.mint}
                >
                  <Image borderRadius="50px" w="30px" src={token.logoURI} />
                  <Text>{token.symbol}</Text>
                  <Text>({displayPubkey(token.mint)})</Text>
                </HStack>
              ))
            ) : (
              <Center my="10px">
                <Spinner />
              </Center>
            )}
            <Box h="10px" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
