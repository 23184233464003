import { useMemo } from "react";
import { useWhitelistedTokens } from "../pages/Tokens/useWhitelistedTokens";
import { TokenDto } from "./useTokensAPI";

export function useTokensMap(): Partial<Record<string, TokenDto>> {
  const { tokens } = useWhitelistedTokens();
  return useMemo(
    () =>
      tokens?.reduce(
        (map, token) => ({
          ...map,
          [token.mint.toString()]: token,
        }),
        {} as Partial<Record<string, TokenDto>>
      ) || {},
    [tokens]
  );
}
