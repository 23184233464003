import { useCallback, useContext, useMemo } from "react";
import { AuthContext } from "../contexts/auth";
import { fetchWithAuth, getBaseAPIUrl } from "../utils/api";
import { TokenDto } from "./useTokensAPI";

/**
 * Right now, the DTO types/interfaces in this file are duplicated from commons/src/api/whirlpool.ts since we can't share that code with the Poolops UI app due to
 * it not being a part of the lerna setup.
 * There is a pending task to resolve this issue: https://app.asana.com/0/1202413563699470/1202606531127077/f
 */

export interface WhirlpoolDto {
  address: string;
  tokenA: TokenDto;
  tokenB: TokenDto;
  tickSpacing: number;
  lpFeeRate?: number;
  whitelisted: boolean;
}

export interface ListWhirlpoolResponse {
  whirlpools: WhirlpoolDto[];
  hasMore: boolean;
}

export interface GetWhirlpoolResponse {
  whirlpool: WhirlpoolDto;
}

export interface CreateWhirlpoolBody {
  address: string;
  tokenMintA: string;
  tokenMintB: string;
  tickSpacing: number;
}

export type CreateWhirlpoolParams = CreateWhirlpoolBody;

export interface WhirlpoolAPI {
  listWhirlpools(): Promise<WhirlpoolDto[]>;
  getWhirlpool(address: string): Promise<WhirlpoolDto>;
  createWhirlpool(params: CreateWhirlpoolParams): Promise<void>;
}

export function useWhirlpoolAPI(): WhirlpoolAPI {
  const baseAPIUrl = getBaseAPIUrl();
  const auth = useContext(AuthContext);

  const listWhirlpools = useCallback(async () => {
    const res = await fetchWithAuth(`${baseAPIUrl}/whirlpool/list`, auth);
    const json: ListWhirlpoolResponse = await res.json();

    return json.whirlpools;
  }, [auth, baseAPIUrl]);

  const getWhirlpool = useCallback(
    async (address: string): Promise<WhirlpoolDto> => {
      const res = await fetchWithAuth(`${baseAPIUrl}/whirlpool/${address}`, auth);
      const json: GetWhirlpoolResponse = await res.json();

      return json.whirlpool;
    },
    [auth, baseAPIUrl]
  );

  const createWhirlpool = useCallback(
    async (params: CreateWhirlpoolParams) => {
      const res = await fetchWithAuth(`${baseAPIUrl}/whirlpool`, auth, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      if (res.status >= 400) {
        throw new Error(`Unable to create whirlpool with params: ${params}`);
      }
    },
    [auth, baseAPIUrl]
  );
  return useMemo(
    () => ({
      listWhirlpools,
      getWhirlpool,
      createWhirlpool,
    }),
    [listWhirlpools, getWhirlpool, createWhirlpool]
  );
}
