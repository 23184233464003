import Decimal from "decimal.js";
import { Address } from "@orca-so/common-sdk";
import { useNetwork } from "./useNetwork";
import { useProvider } from "./useProvider";
import { usePhantom } from "./useWallet";
import { makeBlockExplorerUrl } from "../utils/tx";
import { executeTx } from "../utils/transaction";
import { CreateWhirlpoolParams, useWhirlpoolAPI } from "./useWhirlpoolAPI";
import { useWhirlpoolClient } from "./useWhirlpoolClient";
import { IGNORE_CACHE, ORCA_WHIRLPOOLS_CONFIG, ORCA_WHIRLPOOL_PROGRAM_ID, PDAUtil, PriceMath, toTx, WhirlpoolIx } from "@orca-so/whirlpools-sdk";
import { AddressUtil } from "@orca-so/common-sdk";
import { Keypair } from "@solana/web3.js";

export type InitPoolParams = {
  address: string;
  initialPrice: Decimal;
  tokenMintA: Address;
  tokenMintB: Address;
  stable: boolean;
  tickSpacing: number;
  whitelisted: boolean;
};

export type InitPool = (params: InitPoolParams) => Promise<string>;

export function useInitPool(): InitPool | undefined {
  const phantom = usePhantom();
  const network = useNetwork();
  const provider = useProvider(phantom);
  const client = useWhirlpoolClient();
  const whirlpoolAPI = useWhirlpoolAPI();

  if (!provider || !client) {
    return undefined;
  }

  return async (params) => {
    const [tokenAMintInfo, tokenBMintInfo] = await Promise.all([
      client.getFetcher().getMintInfo(params.tokenMintA, IGNORE_CACHE),
      client.getFetcher().getMintInfo(params.tokenMintB, IGNORE_CACHE),
    ]);

    if (!tokenAMintInfo) {
      throw new Error(`Invalid mint ${params.tokenMintA.toString()}`);
    }

    if (!tokenBMintInfo) {
      throw new Error(`Invalid mint ${params.tokenMintB.toString()}`);
    }

    const initialSqrtPriceX64 = PriceMath.priceToSqrtPriceX64(
      params.initialPrice,
      tokenAMintInfo.decimals,
      tokenBMintInfo.decimals
    );

    // no order check
    // I guess no one use this function because BD team use create-pool function,
    const whirlpoolAddressPda = PDAUtil.getWhirlpool(
      ORCA_WHIRLPOOL_PROGRAM_ID,
      ORCA_WHIRLPOOLS_CONFIG,
      AddressUtil.toPubKey(params.tokenMintA),
      AddressUtil.toPubKey(params.tokenMintB),
      params.tickSpacing
    );
    const feeTierPda = PDAUtil.getFeeTier(ORCA_WHIRLPOOL_PROGRAM_ID, ORCA_WHIRLPOOLS_CONFIG, params.tickSpacing);
    const address = whirlpoolAddressPda.publicKey;
    const tx = toTx(client.getContext(), WhirlpoolIx.initializePoolV2Ix(client.getContext().program, {
      whirlpoolsConfig: ORCA_WHIRLPOOLS_CONFIG,
      feeTierKey: feeTierPda.publicKey,
      whirlpoolPda: whirlpoolAddressPda,
      funder: provider.publicKey,
      tickSpacing: params.tickSpacing,
      tokenMintA: AddressUtil.toPubKey(params.tokenMintA),
      tokenMintB: AddressUtil.toPubKey(params.tokenMintB),
      tokenVaultAKeypair: Keypair.generate(),
      tokenVaultBKeypair: Keypair.generate(),
      tokenBadgeA: PDAUtil.getTokenBadge(ORCA_WHIRLPOOL_PROGRAM_ID, ORCA_WHIRLPOOLS_CONFIG, AddressUtil.toPubKey(params.tokenMintA)).publicKey,
      tokenBadgeB: PDAUtil.getTokenBadge(ORCA_WHIRLPOOL_PROGRAM_ID, ORCA_WHIRLPOOLS_CONFIG, AddressUtil.toPubKey(params.tokenMintB)).publicKey,
      tokenProgramA: tokenAMintInfo.tokenProgram,
      tokenProgramB: tokenBMintInfo.tokenProgram,
      initSqrtPrice: initialSqrtPriceX64,
    }));

    const txId = await executeTx(provider, tx);

    const createWhirlpoolParams: CreateWhirlpoolParams = {
      address: address.toBase58(),
      tokenMintA: params.tokenMintA.toString(),
      tokenMintB: params.tokenMintB.toString(),
      tickSpacing: params.tickSpacing,
    };

    await whirlpoolAPI.createWhirlpool(createWhirlpoolParams);
    return makeBlockExplorerUrl(network, txId);
  };
}
