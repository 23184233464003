import { useCallback, useEffect, useState } from "react";
import { TokenDto } from "./useTokensAPI";
import { useWhirlpoolAPI, WhirlpoolDto } from "./useWhirlpoolAPI";

export interface AllWhirlpoolsHookOutput {
  loading: boolean;
  whirlpools: WhirlpoolDto[];
  reload(): Promise<void>;
  handleFilter(filter?: string): void;
}

export function useAllWhirlpools(): AllWhirlpoolsHookOutput {
  const [loading, setLoading] = useState<boolean>(false);
  const [whirlpools, setWhirlpools] = useState<WhirlpoolDto[]>([]);
  const [filteredWhirlpools, setFilteredWhirlpools] = useState<WhirlpoolDto[]>();
  const whirlpoolAPI = useWhirlpoolAPI();

  const loadWhirlpools = useCallback(async () => {
    const allWhirlpools = await whirlpoolAPI.listWhirlpools();
    setWhirlpools(allWhirlpools);
  }, [whirlpoolAPI]);

  // Run hook once on mount
  useEffect(() => {
    setLoading(true);
    loadWhirlpools().then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = useCallback(
    (filter?: string) => {
      if (!filter) {
        setFilteredWhirlpools(undefined);
        return;
      }

      let filteredResults: WhirlpoolDto[] = [];
      if (filter.includes("/")) {
        const [token1, token2] = filter.split("/");
        filteredResults = whirlpools.filter((whirlpool) =>
          filtersMatchWhirlpoolPair(token1, token2, whirlpool)
        );
      } else {
        filteredResults = whirlpools.filter(
          (whirlpool) =>
            whirlpool.address.includes(filter) ||
            filterMatchesToken(filter, whirlpool.tokenA) ||
            filterMatchesToken(filter, whirlpool.tokenB)
        );
      }

      setFilteredWhirlpools(filteredResults);
    },
    [whirlpools, setFilteredWhirlpools]
  );

  return {
    loading,
    whirlpools: filteredWhirlpools ?? whirlpools,
    reload: loadWhirlpools,
    handleFilter,
  };
}

function filterMatchesToken(filter: string, token: TokenDto): boolean {
  return (
    token.mint.includes(filter) ||
    token.symbol.toLowerCase().includes(filter.toLowerCase()) ||
    token.name.toLowerCase().includes(filter.toLowerCase())
  );
}

function filterMatchesTokenSymbol(filter: string, token: TokenDto): boolean {
  return filter.toLowerCase() === token.symbol.toLowerCase();
}

function filtersMatchWhirlpoolPair(
  filter0: string,
  filter1: string,
  whirlpool: WhirlpoolDto
): boolean {
  const { tokenA, tokenB } = whirlpool;

  return (
    (filterMatchesTokenSymbol(filter0, tokenA) && filterMatchesTokenSymbol(filter1, tokenB)) ||
    (filterMatchesTokenSymbol(filter1, tokenA) && filterMatchesTokenSymbol(filter0, tokenB))
  );
}
