import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Center,
  Code,
  HStack,
  Image,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DecimalUtil, MathUtil } from "@orca-so/common-sdk";
import { WhirlpoolData, PoolUtil, IGNORE_CACHE, WhirlpoolRewardInfoData } from "@orca-so/whirlpools-sdk";
import { AccountInfo } from "@solana/spl-token";
import Decimal from "decimal.js";
import { useCallback, useEffect, useState } from "react";
import { PoolPicker } from "../../components/PoolPicker";
import { TokenDto } from "../../hooks/useTokensAPI";
import { useTokensMap } from "../../hooks/useTokensMap";
import { WhirlpoolDto } from "../../hooks/useWhirlpoolAPI";
import { useWhirlpoolClient } from "../../hooks/useWhirlpoolClient";

export function ViewRewards() {
  const tokensMap = useTokensMap();
  const whirlpoolClient = useWhirlpoolClient();

  const [pool, setPool] = useState<WhirlpoolDto>();
  const [whirlpoolAccount, setWhirlpoolAccount] = useState<WhirlpoolData>();
  const [rewardVaults, setRewardVaults] = useState<(AccountInfo | null | undefined)[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let cancelled = false;
    setLoading(true);

    (async () => {
      if (!whirlpoolClient || !pool) {
        setLoading(false);
        return;
      }

      const fetcher = whirlpoolClient.getFetcher();
      const whirlpoolAccount = await fetcher.getPool(pool.address, IGNORE_CACHE);

      if (!whirlpoolAccount) {
        setLoading(false);
        return;
      }

      const rewardVaultKeys = whirlpoolAccount.rewardInfos.map((info) =>
        PoolUtil.isRewardInitialized(info) ? info.vault : undefined
      );

      const rewardVaultValues = await Promise.all(
        rewardVaultKeys.map((key) => (key ? fetcher.getTokenInfo(key, IGNORE_CACHE) : undefined))
      );

      if (!cancelled) {
        setWhirlpoolAccount(whirlpoolAccount);
        setRewardVaults(rewardVaultValues);
      }
      setLoading(false);
    })();

    return () => {
      cancelled = true;
    };
  }, [pool, whirlpoolClient]);

  const getEnrichedRewardInfo = useCallback(
    (
      rewardIndex: number
    ):
      | {
          account: WhirlpoolRewardInfoData;
          token: TokenDto | undefined;
          vault: AccountInfo | undefined;
        }
      | undefined => {
      if (rewardIndex > 2) throw new Error(`Invalid reward index ${rewardIndex}`);
      if (!whirlpoolAccount) return undefined;

      const rewardInfo = whirlpoolAccount.rewardInfos[rewardIndex];
      if (!PoolUtil.isRewardInitialized(rewardInfo)) return undefined;

      const rewardVaultAccount = rewardVaults?.[rewardIndex];

      return {
        account: rewardInfo,
        token: tokensMap[rewardInfo.mint.toBase58()],
        vault: rewardVaultAccount ?? undefined,
      };
    },
    [rewardVaults, tokensMap, whirlpoolAccount]
  );

  const enrichedRewardInfos = [0, 1, 2].map(getEnrichedRewardInfo);

  return (
    <VStack minW="600px" alignItems="flex-start" spacing="50px">
      <Text fontSize="32px" fontWeight="bold">
        View Rewards
      </Text>
      <Center minW="600px">
        <VStack spacing="50px">
          <PoolPicker pool={pool} onSelect={setPool} />
          {loading ? (
            <Spinner />
          ) : (
            <Accordion>
              {enrichedRewardInfos.some((info) => !!info) ? (
                enrichedRewardInfos.map((info, i) =>
                  info ? (
                    <ViewReward
                      index={i}
                      info={info.account}
                      token={info.token}
                      vault={info.vault}
                    />
                  ) : null
                )
              ) : pool ? (
                <Text>No initialized rewards</Text>
              ) : null}
            </Accordion>
          )}
        </VStack>
      </Center>
    </VStack>
  );
}

function ViewReward({
  info,
  token,
  index,
  vault,
}: {
  index: number;
  info: WhirlpoolRewardInfoData;
  token: TokenDto | undefined;
  vault: AccountInfo | undefined;
}) {
  return (
    <AccordionItem minW="600px">
      <AccordionButton>
        <HStack>
          {token?.logoURI ? <Image h="40px" src={token?.logoURI} /> : null}{" "}
          <Text>
            {token?.symbol ?? info.mint.toBase58()} {`(Reward ${index})`}
          </Text>
        </HStack>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <VStack alignItems="flex-start" spacing="10px">
          <HStack>
            <Text>Reward Token Mint:</Text>
            <Text>
              <Code>{info.mint.toBase58()}</Code>
            </Text>
          </HStack>
          <HStack>
            <Text>Reward Vault Token Account:</Text>
            <Text>
              <Code>{info.vault.toBase58()}</Code>
            </Text>
          </HStack>
          <HStack>
            <Text>Reward Vault Current Balance:</Text>
            <Text>
              {DecimalUtil.adjustDecimals(
                new Decimal(vault?.amount.toString() ?? 0),
                token?.decimals ?? 0
              ).toString()}{" "}
              {token?.symbol ?? info.mint.toBase58()}
            </Text>
          </HStack>
          <HStack>
            <Text>{"Reward Vault Emissions Rate (per second):"}</Text>
            <Text>
              {DecimalUtil.adjustDecimals(
                MathUtil.fromX64(info.emissionsPerSecondX64),
                token?.decimals ?? 0
              )
                .toSignificantDigits(10)
                .toString()}{" "}
              {token?.symbol ?? info.mint.toBase58()} per second
            </Text>
          </HStack>
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
}
