import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Center,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Text,
  Code,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { SearchBar } from "../../components/SearchBar";
import { TokenModal } from "../../components/TokenModal";
import { TokenDto } from "../../hooks/useTokensAPI";

const StyledRow = styled(Tr)`
  transition: 0.2s ease;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.05);
    transition: 0.2s ease;
  }
`;

const StyledTokenIcon = styled.img`
  width: 24px;
  border-radius: 24px;
`;

interface TokenTableProps {
  tokens: TokenDto[];
  refreshTokens: () => unknown;
  onFilter: (filter: string | undefined) => void;
}

export function TokensTable({ tokens, refreshTokens, onFilter }: TokenTableProps) {
  const [selectedToken, setSelectedToken] = useState<TokenDto>();
  const viewToken = useDisclosure();
  const [filter, setFilter] = useState<string>();

  useEffect(() => {
    return () => {
      setFilter(undefined);
      onFilter(undefined);
    };
  }, [onFilter, setFilter]);

  return (
    <VStack>
      <SearchBar
        placeholder="Search tokens"
        filter={filter ?? ""}
        onChange={(newFilter) => {
          setFilter(newFilter);
          onFilter(newFilter || undefined);
        }}
      />
      <Box height="30px" />
      <Table w="1200px" size="lg">
        <Thead>
          <Tr>
            <Th>Token</Th>
            <Th>Mint</Th>
            <Th>
              <Center>Whitelisted</Center>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {tokens.map((token) => (
            <StyledRow
              key={token.mint}
              onClick={(e: any) => {
                setSelectedToken(token);
                viewToken.onOpen();
              }}
            >
              <Td>
                <HStack>
                  <StyledTokenIcon src={token.logoURI} />
                  <Text>{token.symbol}</Text>
                </HStack>
              </Td>
              <Td>
                <Code colorScheme={token.whitelisted ? "green" : "red"}>{token.mint}</Code>
              </Td>
              <Td>
                <Center>{token.whitelisted && <CheckCircleIcon color="#9AE6B4" />}</Center>
              </Td>
            </StyledRow>
          ))}
        </Tbody>
      </Table>
      <TokenModal
        isOpen={viewToken.isOpen}
        onClose={(shouldRefresh) => {
          if (shouldRefresh) {
            refreshTokens();
          }

          viewToken.onClose();
          setSelectedToken(undefined);
        }}
        token={selectedToken}
      />
    </VStack>
  );
}
