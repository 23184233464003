import React, { ReactNode } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  padding: 30px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const StyledRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

type Props = {
  childrenLeft: ReactNode;
  childrenRight: ReactNode;
};

export function Header({ childrenLeft, childrenRight }: Props) {
  return (
    <StyledContainer>
      <StyledLeftContainer>{childrenLeft}</StyledLeftContainer>
      <StyledRightContainer>{childrenRight}</StyledRightContainer>
    </StyledContainer>
  );
}
