import { IGNORE_CACHE, Whirlpool } from "@orca-so/whirlpools-sdk";
import { Address } from "@orca-so/common-sdk";
import { useEffect, useState } from "react";
import { isValidPubkeyStr } from "../utils/pubkey";
import { useWhirlpoolClient } from "./useWhirlpoolClient";

interface WhirlpoolsMap {
  [pubkey: string]: Whirlpool;
}

export function useWhirlpools(pubkeys: Address[]): WhirlpoolsMap {
  const orcaWhirlpoolClient = useWhirlpoolClient();

  const [whirlpoolsMap, setWhirlpoolsMap] = useState<WhirlpoolsMap>({});
  useEffect(() => {
    if (!orcaWhirlpoolClient) {
      return;
    }
    const validPubkeys = pubkeys.filter(isValidPubkeyStr);
    orcaWhirlpoolClient.getPools(validPubkeys, IGNORE_CACHE).then((whirlpools) => {
      const map = Object.fromEntries(whirlpools.map((whirlpool) => [whirlpool.getAddress().toBase58(), whirlpool]));
      setWhirlpoolsMap(map);
    });
  }, [pubkeys, orcaWhirlpoolClient]);

  return whirlpoolsMap;
}
