import React from "react";
import { Search2Icon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

type Props = {
  placeholder: string;
  filter: string;
  onChange: (newFilter: string) => unknown;
};

export function SearchBar({ placeholder, filter, onChange }: Props) {
  return (
    <InputGroup w="1200px">
      <InputLeftElement pointerEvents="none">
        <Search2Icon color="gray.300" />
      </InputLeftElement>
      <Input placeholder={placeholder} value={filter} onChange={(e) => onChange(e.target.value)} />
    </InputGroup>
  );
}
