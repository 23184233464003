import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Spinner,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { PublicKey } from "@solana/web3.js";
import { useState } from "react";
import { PoolPicker } from "../../components/PoolPicker";
import { useAllWhirlpools } from "../../hooks/useAllWhirlpools";
import { useNetwork } from "../../hooks/useNetwork";
import { useProvider } from "../../hooks/useProvider";
import { useTokensMap } from "../../hooks/useTokensMap";
import { usePhantom } from "../../hooks/useWallet";
import { WhirlpoolDto } from "../../hooks/useWhirlpoolAPI";
import { executeTx } from "../../utils/transaction";
import { makeBlockExplorerUrl } from "../../utils/tx";
import { useWhirlpoolClient } from "../../hooks/useWhirlpoolClient";

interface CollectProtocolFeesParam {
  poolAddress: PublicKey;
}

function useCollectProtocolFees(): (params: CollectProtocolFeesParam) => Promise<void> {
  const client = useWhirlpoolClient();
  const network = useNetwork();
  const phantom = usePhantom();
  const provider = useProvider(phantom);
  const toast = useToast();

  return async (params) => {
    let txUrl: string;

    if (!provider) throw new Error("Provider is not defined");
    if (!client) throw new Error("Whirlpool client is not defined");

    try {
      const tx = await client.collectProtocolFeesForPools([params.poolAddress]);
      const txHash = await executeTx(provider, tx);

      txUrl = makeBlockExplorerUrl(network, txHash);
      toast({
        position: "top-right",
        title: "Collect Protocol Fees Successful",
        description: (
          <Link href={txUrl} isExternal>
            View on Solscan
          </Link>
        ),
        status: "success",
        duration: 4_000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        position: "top-right",
        title: "Collect Protocol Fees Failed",
        description: (err as Error).message,
        status: "error",
        duration: 4_000,
        isClosable: true,
      });
    }
  };
}

export function CollectProtocolFees() {
  const [pool, setPool] = useState<WhirlpoolDto>();
  const [loading, setLoading] = useState(false);
  const phantom = usePhantom();
  const { loading: whirlpoolsLoading } = useAllWhirlpools();
  const tokensMap = useTokensMap();
  const collectProtocolFees = useCollectProtocolFees();
  const isReadyToSubmit = tokensMap && !whirlpoolsLoading && pool && phantom;

  async function handleSubmit() {
    if (!isReadyToSubmit) return;

    setLoading(true);

    try {
      await collectProtocolFees({
        poolAddress: new PublicKey(pool.address),
      });
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }

  return (
    <Center w="40%" minW="300px">
      <FormControl w="100%">
        <VStack w="100%" spacing="30px" alignItems="center">
          <VStack alignItems="start" w="100%">
            <FormLabel>Pool</FormLabel>
            <PoolPicker pool={pool} onSelect={setPool} />
          </VStack>

          <Flex flexDir="column" alignItems="center" w="100%">
            <Button
              w="100%"
              py="20px"
              colorScheme="teal"
              mt="20px"
              disabled={!isReadyToSubmit || loading}
              minW="200px"
              onClick={handleSubmit}
            >
              {loading ? (
                <Spinner />
              ) : // TODO: Check if connected phantom is fee authority?
              phantom ? (
                isReadyToSubmit ? (
                  "Collect Protocol Fees"
                ) : (
                  "Enter Details"
                )
              ) : (
                "Connect Phantom"
              )}
            </Button>
          </Flex>
        </VStack>
      </FormControl>
    </Center>
  );
}
