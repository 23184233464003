import { SimpleGrid } from "@chakra-ui/react";
import { MenuCard, MenuIcon } from "../../components/MenuCard";

export function Fees() {
  return (
    <SimpleGrid w="100%" minChildWidth="300px" spacing="80px">
      <MenuCard name="Set Fee Rate" route="/fees/feeRate" icon={MenuIcon.FeeRate} />
      <MenuCard
        name="Set Protocol Fee Rate"
        route="/fees/protocolFeeRate"
        icon={MenuIcon.ProtocolFeeRate}
      />
      <MenuCard name="Collect Protocol Fees" route="/fees/collect" icon={MenuIcon.CollectFees} />
    </SimpleGrid>
  );
}
