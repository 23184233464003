import { IGNORE_CACHE, Whirlpool } from "@orca-so/whirlpools-sdk";
import { Address } from "@orca-so/common-sdk";
import { useEffect, useState } from "react";
import { isValidPubkeyStr } from "../utils/pubkey";
import { useWhirlpoolClient } from "./useWhirlpoolClient";

export function useWhirlpool(pubkey: Address | undefined): Whirlpool | undefined {
  const orcaWhirlpoolClient = useWhirlpoolClient();

  const [whirlpool, setWhirlpool] = useState<Whirlpool | null>();
  useEffect(() => {
    if (isValidPubkeyStr(pubkey) && orcaWhirlpoolClient) {
      orcaWhirlpoolClient.getPool(pubkey, IGNORE_CACHE).then(setWhirlpool);
    } else {
      setWhirlpool(undefined);
    }
  }, [pubkey, orcaWhirlpoolClient]);

  return whirlpool ?? undefined;
}
