import { VStack, Text, Button, Spinner, useToast, Switch } from "@chakra-ui/react";
import { RewardsToRefillDto, RewardToRefillDto } from "@orca-so/orca-commons";
import React, { useCallback, useEffect, useState } from "react";
import { RewardsServiceUnavailable, useRewardAPI } from "../../../hooks/useRewardsAPI";
import { RewardsRefillTable } from "./RewardsRefillTable";

const REWARD_SERVICE_UNAVAILABLE_MESSAGE =
  "Looks like the rewards service is computing rewards, please come back in a bit and reload the page (10-15 mins). If it still doesn't load after a refresh, reach out to a dev.";
const SOMETHING_WENT_WRONG_MESSAGE =
  "Something unexpected happened. Check the console and reach out to a dev if this keeps happening on reloads.";

export const RewardsRefillInbox: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [rewardsToRefill, setRewardsToRefill] = useState<RewardsToRefillDto>();
  const [filteredRewardsToRefill, setFilteredRewardsToRefil] = useState<RewardToRefillDto[]>();
  const [showAllRewards, setShowAllRewards] = useState<boolean>(false);
  const toast = useToast();
  const rewardAPI = useRewardAPI();

  const loadRewards = useCallback(
    (isRefreshing: boolean) => {
      const setLoadingFn = isRefreshing ? setRefreshing : setLoading;

      setLoadingFn(true);
      rewardAPI
        .listRewardsToRefill(isRefreshing)
        .then((data) => {
          setLoadingFn(false);
          setRewardsToRefill(data);
        })
        .catch((err) => {
          if (err instanceof RewardsServiceUnavailable) {
            console.warn(REWARD_SERVICE_UNAVAILABLE_MESSAGE);
            toast({
              position: "top-right",
              title: "Rewards Service Unavailable",
              description: REWARD_SERVICE_UNAVAILABLE_MESSAGE,
              status: "warning",
              duration: 20_000,
              isClosable: true,
            });
          } else {
            console.error(err);
            toast({
              position: "top-right",
              title: "Something went wrong",
              description: SOMETHING_WENT_WRONG_MESSAGE,
              status: "error",
              duration: 20_000,
              isClosable: true,
            });
          }

          setLoadingFn(false);
        });
    },
    [rewardAPI, toast]
  );

  const forceRefresh = useCallback(() => {
    loadRewards(true);
  }, [loadRewards]);

  const archiveRewardAlert = useCallback(
    async (whirlpool: string, rewardIndex: 0 | 1 | 2) => {
      return rewardAPI.markRewardAsRefilled(whirlpool, rewardIndex).then(() => loadRewards(false));
    },
    [loadRewards, rewardAPI]
  );

  useEffect(() => {
    loadRewards(false);
  }, [loadRewards]);

  useEffect(() => {
    if (rewardsToRefill) {
      const filteredList = rewardsToRefill.list.filter((r) => r.status !== "okay");
      setFilteredRewardsToRefil(filteredList);
    }
  }, [rewardsToRefill]);

  const listToRender = showAllRewards ? rewardsToRefill?.list : filteredRewardsToRefill;
  const renderedRewardsCount = listToRender?.length ?? 0;

  return (
    <VStack>
      {rewardsToRefill && (
        <>
          <Text fontSize="24px" fontWeight="bold">
            Rewards Alert Inbox
            <Button onClick={forceRefresh} disabled={loading || refreshing} ml="20px">
              {loading || refreshing ? <Spinner /> : "Force Refresh"}
            </Button>
          </Text>
          <Text>
            <Text display="inline" fontWeight="bold">
              {"Generated At: "}
            </Text>
            <Text display="inline">{new Date(rewardsToRefill.generatedAt).toLocaleString()}</Text>
            <Text display="inline">{` (${renderedRewardsCount} rewards)`}</Text>
          </Text>
        </>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Switch onChange={(e) => setShowAllRewards(e.target.checked)}>Show All Rewards</Switch>
          <RewardsRefillTable onArchive={archiveRewardAlert} rewardsToRefill={listToRender ?? []} />
        </>
      )}
    </VStack>
  );
};
