import { Box, Text } from "@chakra-ui/react";
import styled from "styled-components";
import SolanaIcon from "../assets/solana.svg";
import { OrcaNetwork } from "../utils/network";
import { useNetwork } from "../hooks/useNetwork";

const StyledIcon = styled.img``;

function displayNetwork(network: OrcaNetwork): string {
  return `${network === OrcaNetwork.MAINNET ? "🚀" : "🛠️ "} ${network
    .slice(0, 1)
    .toUpperCase()}${network.slice(1)}`;
}

export function NetworkIndicator() {
  const network = useNetwork();

  return (
    <Box display="flex" flexDir="row" alignItems="center" justifyContent="space-around" w="120px">
      <StyledIcon width="25px" style={{ paddingRight: "5px" }} src={SolanaIcon} />
      <Text color="white">{displayNetwork(network)}</Text>
    </Box>
  );
}
