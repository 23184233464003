export enum OrcaNetwork {
  MAINNET = "mainnet",
  DEVNET = "devnet",
}

function isOrcaNetwork(val: any): val is OrcaNetwork {
  return val && [OrcaNetwork.MAINNET, OrcaNetwork.DEVNET].includes(val);
}

const NETWORK_NAME_ENV_VAR = process.env.REACT_APP_SOLANA_NETWORK_NAME;
if (!isOrcaNetwork(NETWORK_NAME_ENV_VAR)) {
  throw new Error("REACT_APP_SOLANA_NETWORK_NAME not defined correctly");
}

const NETWORK_RPC_URL_ENV_VAR = process.env.REACT_APP_SOLANA_NETWORK;
if (typeof NETWORK_RPC_URL_ENV_VAR !== "string") {
  throw new Error("REACT_APP_SOLANA_NETWORK not defined correctly");
}

export const NETWORK_NAME: OrcaNetwork = NETWORK_NAME_ENV_VAR;
export const NETWORK_RPC_URL: string = NETWORK_RPC_URL_ENV_VAR;
