import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Select,
  Spinner,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { displayPubkey } from "../../utils/pubkey";
import DatePicker from "react-datetime-picker";
import styled from "styled-components";
import Decimal from "decimal.js";
import { PublicKey } from "@solana/web3.js";
import { useNetwork } from "../../hooks/useNetwork";
import { PreciseDecimal } from "../../utils/decimal";
import { BN } from "@coral-xyz/anchor";
import { usePhantom } from "../../hooks/useWallet";
import { useProvider } from "../../hooks/useProvider";
import { makeBlockExplorerUrl } from "../../utils/tx";
import { useWhirlpool } from "../../hooks/useWhirlpool";
import { PoolPicker } from "../../components/PoolPicker";
import { executeTx } from "../../utils/transaction";
import { WhirlpoolDto } from "../../hooks/useWhirlpoolAPI";
import { useTokensMap } from "../../hooks/useTokensMap";
import { useWhirlpoolClient } from "../../hooks/useWhirlpoolClient";
import { IGNORE_CACHE, toTx, WhirlpoolIx, WhirlpoolRewardInfo } from "@orca-so/whirlpools-sdk";
import { DecimalUtil } from "@orca-so/common-sdk";

const StyledDatePicker = styled(DatePicker)`
  color: white;
  width: 100%;
  padding: 10px 10px;
  background: rgb(41, 44, 51);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 5px;
`;

interface SetEmissionsParams {
  poolAddress: PublicKey;
  rewardIndex: number;
  totalEmissions: string;
  startDate: Date;
  endDate: Date;
}

function useSetEmissions(): (params: SetEmissionsParams) => Promise<void> {
  const client = useWhirlpoolClient();
  const network = useNetwork();
  const phantom = usePhantom();
  const provider = useProvider(phantom);
  const toast = useToast();

  return async (params) => {
    let txUrl: string;

    try {
      if (!provider) throw new Error("Provider is null, please connect wallet");
      if (!client) throw new Error("Whirlpool client is null");

      const whirlpool = await client.getPool(params.poolAddress, IGNORE_CACHE);
      if (!whirlpool) throw new Error(`Invalid whirlpool ID ${params.poolAddress.toBase58()}`);

      const rewardInfo = whirlpool.getRewardInfos()[params.rewardIndex];
      if (!rewardInfo || rewardInfo.mint.toBase58() === PLACEHOLDER_REWARD_MINT)
        throw new Error(`Invalid reward index ${params.rewardIndex}`);

      const rewardMint = rewardInfo.mint;
      const rewardMintInfo = await client.getFetcher().getMintInfo(rewardMint, IGNORE_CACHE);
      if (!rewardMintInfo) throw new Error(`Invalid reward mint ${rewardMint.toBase58()}`);

      const totalSeconds = Math.ceil((params.endDate.getTime() - params.startDate.getTime()) / 1e3);
      if (totalSeconds <= 0) throw new Error(`Invalid total seconds ${totalSeconds}`);

      const totalRewardsForDuration = new PreciseDecimal(params.totalEmissions);
      const totalRewardsForDurationScaled = totalRewardsForDuration
        .mul(new PreciseDecimal(10).pow(rewardMintInfo.decimals))
        .floor();
      const emissionsPerSecond = new BN(
        totalRewardsForDurationScaled.div(totalSeconds).floor().toString()
      );
      const emissionsPerSecondX64 = emissionsPerSecond.shln(64);

      const tx = toTx(client.getContext(), WhirlpoolIx.setRewardEmissionsV2Ix(client.getContext().program, {
        whirlpool: params.poolAddress,
        rewardVaultKey: rewardInfo.vault,
        rewardAuthority: provider.publicKey,
        rewardIndex: params.rewardIndex,
        emissionsPerSecondX64,
      }));

      const txHash = await executeTx(provider, tx);
      txUrl = makeBlockExplorerUrl(network, txHash);
      toast({
        position: "top-right",
        title: "Set Reward Emissions Successful",
        description: (
          <Link href={txUrl} isExternal>
            View on Solscan
          </Link>
        ),
        status: "success",
        duration: 4_000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        position: "top-right",
        title: "Set Reward Emissions Failed",
        description: (err as Error).message,
        status: "error",
        duration: 4_000,
        isClosable: true,
      });
    }
  };
}

const PLACEHOLDER_REWARD_MINT = "11111111111111111111111111111111";
const TWO_WEEKS_IN_MS = 2 * 7 * 24 * 60 * 60 * 1000;

export function SetRewardEmissions() {
  const tokensMap = useTokensMap();
  const [pool, setPool] = useState<WhirlpoolDto>();
  const [rewardIndex, setRewardIndex] = useState<string>();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date(new Date().getTime() + TWO_WEEKS_IN_MS));
  const [vaultAmount, setVaultAmount] = useState("");
  const [totalEmissions, setTotalEmissions] = useState<string>("0");
  const [loading, setLoading] = useState(false);
  const phantom = usePhantom();
  const provider = useProvider(phantom);

  const setEmissions = useSetEmissions();

  const whirlpool = useWhirlpool(pool?.address);

  const rewards: Array<WhirlpoolRewardInfo & { index: number }> = useMemo(() => {
    if (!whirlpool) return [];

    return whirlpool.getRewardInfos()
      .filter((reward) => reward.mint.toBase58() !== PLACEHOLDER_REWARD_MINT)
      .map((reward, i) => ({
        ...reward,
        index: i,
      }));
  }, [whirlpool]);

  useEffect(() => {
    setRewardIndex(undefined);
  }, [pool]);

  useEffect(() => {
    const reward = rewards?.[Number(rewardIndex)];
    if (!reward) {
      return;
    }

    const rewardMint = reward.mint;
    const rewardVaultAmount = reward.vaultAmount;
    const rewardDecimals = tokensMap[rewardMint.toBase58()]?.decimals;
    if (rewardDecimals !== undefined) {
      setVaultAmount(DecimalUtil.fromBN(rewardVaultAmount, rewardDecimals).toString());
      return;
    }

    if (provider) {
      (async () => {
        const response = await provider.connection.getTokenAccountBalance(reward.vault);
        setVaultAmount(response.value.uiAmountString || "");
      })();  
    }
  }, [provider, rewards, rewardIndex, tokensMap]);

  const emissionsPerSecond = useMemo(() => {
    const startDateTs = startDate.getTime() / 1e3;
    const endDateTs = endDate.getTime() / 1e3;
    const totalDurationInSeconds = endDateTs - startDateTs;

    return new Decimal(totalEmissions || 0).div(totalDurationInSeconds).toString();
  }, [endDate, startDate, totalEmissions]);

  const isReadyToSubmit =
    pool &&
    rewardIndex !== undefined &&
    rewardIndex !== "" &&
    totalEmissions !== undefined &&
    totalEmissions !== "" &&
    startDate &&
    endDate;

  async function handleSubmit() {
    if (!isReadyToSubmit) return;

    setLoading(true);

    try {
      await setEmissions({
        poolAddress: new PublicKey(pool.address),
        rewardIndex: Number(rewardIndex),
        totalEmissions,
        startDate,
        endDate,
      });
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }

  return (
    <Center w="40%" minW="300px">
      <FormControl w="100%">
        <VStack spacing="30px" alignItems="start">
          <VStack alignItems="start" w="100%">
            <FormLabel>Pool</FormLabel>
            <PoolPicker pool={pool} onSelect={setPool} />
          </VStack>
          <Box w="100%">
            <FormLabel>Reward Index</FormLabel>
            <Select
              placeholder="Select reward index"
              value={rewardIndex}
              onChange={(e) => setRewardIndex(e.target.value)}
            >
              {rewards.map((reward) => (
                <option value={reward.index.toString()} key={reward.index.toString()}>
                  {`(${reward.index}) ${
                    tokensMap[reward.mint.toBase58()]?.symbol ?? "EMPTY"
                  } - ${displayPubkey(reward.mint)}`}
                </option>
              ))}
            </Select>
          </Box>
          <Box w="100%">
            <FormLabel>Reward Token Vault</FormLabel>
            <Input
              type="string"
              disabled
              value={rewards?.[Number(rewardIndex)]?.vault.toBase58() || ""}
            />
          </Box>
          <Box w="100%">
            <FormLabel>Reward Token Vault Balance</FormLabel>
            <Input type="string" disabled value={vaultAmount} />
          </Box>
          <Box w="100%">
            <FormLabel>Emissions Start Time</FormLabel>
            <StyledDatePicker
              disableClock
              disableCalendar
              value={startDate}
              onChange={(date: Date) => setStartDate(date)}
            />
          </Box>
          <Box w="100%">
            <FormLabel>Emissions End Time</FormLabel>
            <StyledDatePicker
              disableClock
              disableCalendar
              value={endDate}
              onChange={(date: Date) => setEndDate(date)}
            />
          </Box>
          <Box w="100%">
            <FormLabel>Total Emissions</FormLabel>
            <Input
              type="number"
              placeholder="Enter total emissions during period"
              value={totalEmissions}
              onChange={(e) => setTotalEmissions(e.target.value)}
            />
          </Box>
          <Box w="100%">
            <FormLabel>Emissions per second</FormLabel>
            <Input
              type="number"
              disabled
              placeholder="ENTER TOTAL EMISSIONS AND DATES TO COMPUTE"
              value={emissionsPerSecond}
            />
          </Box>

          <Flex flexDir="column" alignItems="end" w="100%">
            <Button
              w="30%"
              py="20px"
              colorScheme="teal"
              mt="20px"
              disabled={!isReadyToSubmit || !phantom || !setEmissions || loading}
              minW="200px"
              onClick={handleSubmit}
            >
              {loading ? (
                <Spinner />
              ) : // TODO: Check if connected phantom is reward authority
              phantom ? (
                isReadyToSubmit ? (
                  "Set Emissions"
                ) : (
                  "Enter Details"
                )
              ) : (
                "Connect Phantom"
              )}
            </Button>
          </Flex>
        </VStack>
      </FormControl>
    </Center>
  );
}
