import {
  buildWhirlpoolClient,
  ORCA_WHIRLPOOL_PROGRAM_ID,
  WhirlpoolClient,
  WhirlpoolContext as WhirlpoolSdkContext,
} from "@orca-so/whirlpools-sdk";
import { Wallet } from "@orca-so/common-sdk";
import { Connection, PublicKey, Transaction, VersionedTransaction } from "@solana/web3.js";
import React, { useEffect, useState } from "react";
import { usePhantom } from "../hooks/useWallet";
import { NETWORK_RPC_URL } from "../utils/network";

interface WhirlpoolContextValue {
  whirlpoolClient: WhirlpoolClient;
}

export const WhirlpoolContext = React.createContext<WhirlpoolContextValue | undefined>(undefined);

export const WhirlpoolContextProvider: React.FC = ({ children }) => {
  const [value, setValue] = useState<WhirlpoolContextValue | undefined>(undefined);
  const wallet = usePhantom();

  useEffect(() => {
    const connection = new Connection(NETWORK_RPC_URL, "confirmed");
    const client = buildWhirlpoolClient(
      WhirlpoolSdkContext.from(
        connection,
        wallet ?? new ReadOnlyWallet(),
        ORCA_WHIRLPOOL_PROGRAM_ID
      )
    );

    setValue({ whirlpoolClient: client });
  }, [wallet]);

  return <WhirlpoolContext.Provider value={value}>{children}</WhirlpoolContext.Provider>;
};

class ReadOnlyWallet implements Wallet {
  //signTransaction(_tx: Transaction | VersionedTransaction): Promise<Transaction> {
  signTransaction<T extends Transaction | VersionedTransaction>(tx: T): Promise<T> {
    throw new Error("Read only wallet cannot sign transactions.");
  }
  //signAllTransactions(_txs: Transaction[] | VersionedTransaction[]): Promise<Transaction[]> {
  signAllTransactions<T extends Transaction | VersionedTransaction>(txs: T[]): Promise<T[]> {
    throw new Error("Read only wallet cannot sign transactions.");
  }
  publicKey: PublicKey = PublicKey.default;
}
