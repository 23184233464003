import { Address } from "@orca-so/common-sdk";
import Decimal from "decimal.js";
import { useEffect, useMemo, useState } from "react";

export function useWhirlpoolPrice(
  tokenMintA: Address | undefined,
  tokenMintB: Address | undefined
): [Decimal | undefined, boolean] {
  const { prices, loading } = useBeachhousePrices();

  const price = useMemo(() => {
    const tokenAPrice = prices[tokenMintA?.toString() ?? ""];
    const tokenBPrice = prices[tokenMintB?.toString() ?? ""];
    if (!tokenAPrice || !tokenBPrice) {
      return undefined;
    }
    return new Decimal(tokenAPrice).div(tokenBPrice).toSignificantDigits(9);
  }, [tokenMintA, tokenMintB, prices]);
  
  return [price, loading];
}

const useBeachhousePrices = () => {
  const [prices, setPrices] = useState<Record<string, number>>({});
  const [loading, setLoading] = useState(false);

  const fetchPrices = async () => {
    setLoading(true);
    const prices = await fetchBeachhousePrices();
    setPrices(prices);
    setLoading(false);
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  return { prices, loading, fetchPrices };
}

const fetchBeachhousePrices = async (): Promise<Record<string, number>> => {
  // Replace with actual API endpoint
  const response = await fetch("http://bh-api.orca.so/api/prices-simple?sources[]=coingecko");
  if (response.status !== 200) {
    return {};
  }
  return (await response.json()).data;
};