import { Button } from "@chakra-ui/react";

export function SignInButton() {
  return (
    <Button
      w="200px"
      height="80px"
      fontSize="36px"
      onClick={() => {
        window.location.href = process.env.REACT_APP_POOLOPS_COGNITO_UI_URL!;
      }}
    >
      Sign In
    </Button>
  );
}
