import React, { useEffect, useState } from "react";
import { Button, Spinner, Text } from "@chakra-ui/react";
import { Phantom } from "../types/wallet";
import PhantomIcon from "../assets/phantom.svg";
import styled from "styled-components";

const StyledIcon = styled.img``;

type Props = {
  onConnect: (phantom: Phantom) => void;
  onDisconnect: () => void;
  onError: (err: Error) => void;
  isConnected: boolean;
};

function getPhantom() {
  return (window as any).solana;
}

function isPhantomInstalled() {
  return getPhantom()?.isPhantom;
}

export function PhantomConnectButton({ onConnect, onDisconnect, onError, isConnected }: Props) {
  const [isConnecting, setIsConnecting] = useState(false);
  // TODO(atamari): Throw an error toast if wallet detected is not phantom

  // eager connect
  useEffect(() => {
    if (!isPhantomInstalled()) {
      return;
    }

    setIsConnecting(true);

    try {
      // Will either automatically connect to Phantom, or do nothing.
      getPhantom()
        .connect({ onlyIfTrusted: true })
        .then((phantom: Phantom) => {
          // Handle successful eager connection
          // { ...getPhantom() } no longer works well with Phantom v23.8.0
          onConnect(getPhantom());
        })
        .catch(() => {})
        .finally(() => {
          setIsConnecting(false);
        });
    } catch {
      // Silently fail eager connect
      setIsConnecting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function connectPhantom() {
    setIsConnecting(true);

    try {
      if (!isPhantomInstalled()) {
        throw new Error("Phantom extension not found");
      }

      // { ...getPhantom() } no longer works well with Phantom v23.8.0
      await getPhantom().connect();
      onConnect(getPhantom());
    } catch (err) {
      onError(err as Error);
    }
    setIsConnecting(false);
  }

  async function disconnectPhantom() {
    try {
      await getPhantom().disconnect();
      onDisconnect();
    } catch (err) {
      onError(err as Error);
    }
  }

  return (
    <Button
      leftIcon={<StyledIcon width="20px" src={PhantomIcon} />}
      zIndex={2}
      colorScheme="whiteAlpha"
      w="150px"
      onClick={isConnected ? disconnectPhantom : connectPhantom}
    >
      {isConnecting ? (
        <Spinner colorScheme="whiteAlpha" />
      ) : (
        <Text color="white">{isConnected ? "Disconnect" : "Connect"}</Text>
      )}
    </Button>
  );
}
