import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Select,
  Spinner,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { AddressUtil } from "@orca-so/common-sdk";
import { SetRewardAuthorityParams, toTx, WhirlpoolIx } from "@orca-so/whirlpools-sdk";
import { useState } from "react";
import { PoolPicker } from "../../components/PoolPicker";
import { useNetwork } from "../../hooks/useNetwork";
import { useProvider } from "../../hooks/useProvider";
import { useTokensMap } from "../../hooks/useTokensMap";
import { usePhantom } from "../../hooks/useWallet";
import { useWhirlpool } from "../../hooks/useWhirlpool";
import { WhirlpoolDto } from "../../hooks/useWhirlpoolAPI";
import { useWhirlpoolClient } from "../../hooks/useWhirlpoolClient";
import { displayPubkey } from "../../utils/pubkey";
import { executeTx } from "../../utils/transaction";
import { makeBlockExplorerUrl } from "../../utils/tx";

function useSetRewardAuthority(): (params: SetRewardAuthorityParams) => Promise<void> {
  const client = useWhirlpoolClient();
  const network = useNetwork();
  const phantom = usePhantom();
  const provider = useProvider(phantom);
  const toast = useToast();

  return async (params) => {
    let txUrl: string;

    try {
      if (!provider) throw new Error("Provider not loaded");
      if (!client) throw new Error("Whirlpool client not loaded");

      const tx = toTx(client.getContext(), WhirlpoolIx.setRewardAuthorityIx(client.getContext().program, params));
      const txHash = await executeTx(provider, tx);

      txUrl = makeBlockExplorerUrl(network, txHash);
      toast({
        position: "top-right",
        title: "Set Reward Authority Successful",
        description: (
          <Link href={txUrl} isExternal>
            View on Solscan
          </Link>
        ),
        status: "success",
        duration: 4_000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        position: "top-right",
        title: "Set Reward Authority Failed",
        description: (err as Error).message,
        status: "error",
        duration: 4_000,
        isClosable: true,
      });
    }
  };
}

const PLACEHOLDER_REWARD_MINT = "11111111111111111111111111111111";

export function SetRewardAuthority() {
  const phantom = usePhantom();
  const provider = useProvider(phantom);
  const [pool, setPool] = useState<WhirlpoolDto>();
  const [rewardIndex, setRewardIndex] = useState<string>();
  const [newRewardAuthority, setNewRewardAuthority] = useState<string>();
  const tokensMap = useTokensMap();
  const whirlpool = useWhirlpool(pool?.address);
  const rewards =
    whirlpool?.getRewardInfos().filter((reward) => reward.mint.toBase58() !== PLACEHOLDER_REWARD_MINT) ??
    [];
  const [loading, setLoading] = useState(false);

  const isReadyToSubmit = provider && phantom && pool && rewardIndex && newRewardAuthority;

  const setRewardAuthority = useSetRewardAuthority();

  const uninitializedRewardIndexStart = rewards.length;

  async function handleSubmit() {
    if (!isReadyToSubmit) return;

    setLoading(true);

    try {
      await setRewardAuthority({
        whirlpool: AddressUtil.toPubKey(pool.address),
        rewardIndex: Number(rewardIndex),
        rewardAuthority: provider.publicKey,
        newRewardAuthority: AddressUtil.toPubKey(newRewardAuthority),
      });
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }

  return (
    <Center w="40%" minW="300px">
      <FormControl w="100%">
        <VStack spacing="30px" alignItems="start">
          <VStack alignItems="start" w="100%">
            <FormLabel>Pool</FormLabel>
            <PoolPicker pool={pool} onSelect={setPool} />
          </VStack>
          <Box w="100%">
            <FormLabel>Reward Index</FormLabel>
            <Select
              placeholder="Select reward index"
              value={rewardIndex}
              onChange={(e) => setRewardIndex(e.target.value)}
            >
              {rewards.map((reward, i) => (
                <option value={i.toString()}>
                  {`(${i}) ${tokensMap[reward.mint.toBase58()]?.symbol} - ${displayPubkey(
                    reward.mint
                  )}`}
                </option>
              ))}
              {Array(3 - uninitializedRewardIndexStart)
                .fill(null)
                .map((_, i) => {
                  const rewardIndex = uninitializedRewardIndexStart + i;
                  return (
                    <option value={rewardIndex.toString()}>
                      {`(${rewardIndex}) UNINITIALIZED`}
                    </option>
                  );
                })}
            </Select>
          </Box>
          <Box w="100%">
            <FormLabel>New Reward Authority</FormLabel>
            <Input
              placeholder="Enter new reward authority pubkey"
              value={newRewardAuthority}
              onChange={(e) => setNewRewardAuthority(e.target.value)}
            />
          </Box>

          <Flex flexDir="column" alignItems="end" w="100%">
            <Button
              w="30%"
              py="20px"
              colorScheme="teal"
              mt="20px"
              disabled={!isReadyToSubmit || !phantom || !setRewardAuthority || loading}
              minW="200px"
              onClick={handleSubmit}
            >
              {loading ? (
                <Spinner />
              ) : // TODO: Check if connected phantom is reward authority
              phantom ? (
                isReadyToSubmit ? (
                  "Set Reward Authority"
                ) : (
                  "Enter Details"
                )
              ) : (
                "Connect Phantom"
              )}
            </Button>
          </Flex>
        </VStack>
      </FormControl>
    </Center>
  );
}
