import {
  Button,
  HStack,
  useDisclosure,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  Box,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { useMemo } from "react";
import styled from "styled-components";
import { useAllWhirlpools } from "../hooks/useAllWhirlpools";
import { WhirlpoolDto } from "../hooks/useWhirlpoolAPI";
import { displayPubkey } from "../utils/pubkey";

interface PoolPickerProps {
  onSelect(pool: WhirlpoolDto): void;
  pool?: WhirlpoolDto;
  disabled?: boolean;
}

export function PoolPicker({ onSelect, pool, disabled = false }: PoolPickerProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { whirlpools, loading, handleFilter } = useAllWhirlpools();

  const tokenA = useMemo(() => pool && pool.tokenA, [pool]);
  const tokenB = useMemo(() => pool && pool.tokenB, [pool]);

  return (
    <>
      <Button disabled={disabled} h="42px" onClick={onOpen}>
        {pool ? (
          <HStack>
            <StyledTokenIcon src={tokenA?.logoURI} style={{ zIndex: 2 }} />
            <StyledTokenIcon
              src={tokenB?.logoURI}
              style={{ position: "relative", left: "-20px" }}
            />
            <Text position="relative" left="-10px">
              {`${tokenA?.symbol}/${tokenB?.symbol}`}
            </Text>
            <Text>({displayPubkey(pool.address)})</Text>
          </HStack>
        ) : (
          "Pool Picker"
        )}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pick a Pool</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter token symbol or mint or pool pubkey"
              onChange={(e) => {
                handleFilter(e.target.value);
              }}
            />
            <Box h="10px" />
            {!loading && whirlpools ? (
              whirlpools.map((pool) => {
                const { tokenA, tokenB } = pool;

                return (
                  <HStack
                    key={pool.address.toString()}
                    onClick={() => {
                      handleFilter(undefined);
                      onSelect(pool);
                      onClose();
                    }}
                    borderRadius="10px"
                    cursor="pointer"
                    _hover={{
                      bgColor: "whiteAlpha.100",
                    }}
                    p="10px"
                  >
                    <StyledTokenIcon src={tokenA?.logoURI} style={{ zIndex: 2 }} />
                    <StyledTokenIcon
                      src={tokenB?.logoURI}
                      style={{ position: "relative", left: "-20px" }}
                    />
                    <Text position="relative" left="-10px">
                      {`${tokenA?.symbol}/${tokenB?.symbol}`}
                    </Text>
                    <Text>
                      ({displayPubkey(pool.address)})
                      {pool.lpFeeRate ? `  (${pool.lpFeeRate * 100}%)` : ""}
                    </Text>
                  </HStack>
                );
              })
            ) : (
              <Center my="10px">
                <Spinner />
              </Center>
            )}
            <Box h="10px" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

const StyledTokenIcon = styled(Image)`
  width: 24px;
  border-radius: 24px;
`;
