import {
  Text,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
  VStack,
  Switch,
} from "@chakra-ui/react";
import { isNumeric } from "@chakra-ui/utils";
import { PublicKey } from "@solana/web3.js";
import Decimal from "decimal.js";
import { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import styled from "styled-components";
import { useInitPool } from "../hooks/useInitPool";
import { TokenDto } from "../hooks/useTokensAPI";
import { usePhantom } from "../hooks/useWallet";
import { WhirlpoolDto } from "../hooks/useWhirlpoolAPI";
import { useWhirlpoolPrice } from "../hooks/useWhirlpoolPrice";
import { TokenPicker } from "./TokenPicker";
import { ORCA_WHIRLPOOLS_CONFIG, ORCA_WHIRLPOOL_PROGRAM_ID, PDAUtil } from "@orca-so/whirlpools-sdk";

const StyledText = styled(Text)`
  font-size: 12px;
  opacity: 0.6;
  transition: 0.2 ease;
`;

const StyledClickableText = styled(Text)`
  font-size: 12px;
  opacity: 0.6;
  transition: 0.2 ease;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    transition: 0.2 ease;
    opacity: 0.8;
  }
`;

const DEFAULT_TICK_SPACING = 64;

export enum PoolModalMode {
  Init,
  View,
}

enum PoolPriceEntryMode {
  Raw, // Enter raw B/A price
  UsdPrice, // Enter USD price of A and B separately
}

type PDA = {
  publicKey: PublicKey;
  bump: number;
};

type Props = {
  mode: PoolModalMode;
  isOpen: boolean;
  onClose: () => unknown;
  pool?: WhirlpoolDto;
};

function isLessThan(address0: PublicKey, address1: PublicKey): boolean {
  return Buffer.compare(address0.toBuffer(), address1.toBuffer()) < 0;
}

export function PoolModal({ mode, isOpen, onClose, pool }: Props) {
  const phantom = usePhantom();
  const initPool = useInitPool();
  const [tokenA, setTokenA] = useState<TokenDto>();
  const [tokenB, setTokenB] = useState<TokenDto>();
  const [poolPriceEntryMode, setPoolPriceEntryMode] = useState<PoolPriceEntryMode>(
    PoolPriceEntryMode.UsdPrice
  );
  const [initialPriceRaw, setInitialPriceRaw] = useState<string>();
  const [tokenAUSDPriceRaw, setTokenAUSDPriceRaw] = useState<string>();
  const [tokenBUSDPriceRaw, setTokenBUSDPriceRaw] = useState<string>();
  const [tickSpacing, setTickSpacing] = useState<number>(DEFAULT_TICK_SPACING);
  const [whitelisted, setWhitelisted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const [whirlpoolPrice, whirlpoolPriceLoading] = useWhirlpoolPrice(
    pool?.tokenA.mint ?? tokenA?.mint,
    pool?.tokenB.mint ?? tokenB?.mint
  );

  useEffect(() => {
    if (tokenAUSDPriceRaw && tokenBUSDPriceRaw) {
      setInitialPriceRaw(new Decimal(tokenAUSDPriceRaw).div(tokenBUSDPriceRaw).toString());
    }
  }, [tokenAUSDPriceRaw, tokenBUSDPriceRaw]);

  // Sync pool with whitelisted
  useEffect(() => {
    if (pool) {
      setWhitelisted(pool.whitelisted);
    }
  }, [pool]);

  const poolAddress = useMemo(() => {
    if (!tokenA || !tokenB) {
      return undefined;
    }

    let mintA = new PublicKey(tokenA.mint);
    let mintB = new PublicKey(tokenB.mint);

    [mintA, mintB] = isLessThan(mintA, mintB) ? [mintA, mintB] : [mintB, mintA];

    let pda: PDA;

    try {
      pda = PDAUtil.getWhirlpool(ORCA_WHIRLPOOL_PROGRAM_ID, ORCA_WHIRLPOOLS_CONFIG, mintA, mintB, tickSpacing);
    } catch (err) {
      console.error("Error deriving pool PDA", err);
      return undefined;
    }

    return pda;
  }, [tokenA, tokenB, tickSpacing]);

  const identifyTokenAAndB = useCallback(() => {
    if (!tokenA || !tokenB) {
      return;
    }

    const [actualTokenA, actualTokenB] = isLessThan(
      new PublicKey(tokenA.mint),
      new PublicKey(tokenB.mint)
    )
      ? [tokenA, tokenB]
      : [tokenB, tokenA];

    setTokenA(actualTokenA);
    setTokenB(actualTokenB);
  }, [tokenA, tokenB]);

  useEffect(() => {
    identifyTokenAAndB();
  }, [identifyTokenAAndB]);

  function handleOnClose() {
    setTokenA(undefined);
    setTokenB(undefined);
    setTickSpacing(DEFAULT_TICK_SPACING);
    setWhitelisted(false);
    setInitialPriceRaw(undefined);
    setTokenAUSDPriceRaw(undefined);
    setTokenBUSDPriceRaw(undefined);
    setPoolPriceEntryMode(PoolPriceEntryMode.UsdPrice);
    onClose();
  }

  async function handleOnInitPool() {
    if (!initPool || !initialPriceRaw || !tokenA || !tokenB || !poolAddress) return;

    const initialPrice = new Decimal(initialPriceRaw);

    try {
      const blockExplorerTxUrl = await initPool({
        address: poolAddress.publicKey.toBase58(),
        initialPrice,
        tokenMintA: tokenA.mint,
        tokenMintB: tokenB.mint,
        tickSpacing,
        stable: tickSpacing !== DEFAULT_TICK_SPACING,
        whitelisted,
      });

      toast({
        position: "top-right",
        title: "Initialized Pool",
        description: (
          <Link href={blockExplorerTxUrl} isExternal>
            View on Solscan
          </Link>
        ),
        status: "success",
        duration: 4_000,
        isClosable: true,
      });
    } catch (err) {
      console.error("INIT POOL FAILED", {
        err,
      });
      toast({
        position: "top-right",
        title: "Pool Init Failed",
        description: (err as Error).message,
        status: "error",
        duration: 4_000,
        isClosable: true,
      });
    }
  }

  async function handlePrimaryClick() {
    setLoading(true);
    try {
      switch (mode) {
        case PoolModalMode.Init:
          await handleOnInitPool();
          break;
        default:
          break;
      }
    } catch (err) {
      console.error(err);
    }
    handleOnClose();
    setLoading(false);
  }

  const readyToSubmit =
    (mode === PoolModalMode.Init &&
      tokenA &&
      tokenB &&
      poolAddress &&
      initPool &&
      initialPriceRaw &&
      isNumeric(initialPriceRaw) &&
      isLessThan(new PublicKey(tokenA.mint), new PublicKey(tokenB.mint))) ||
    (mode === PoolModalMode.View && pool);

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {(() => {
            switch (mode) {
              case PoolModalMode.Init:
                return "Init Pool";
            }
          })()}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <VStack alignItems="start">
              {/* TOOD: Make the token mint fields searchable on whitelisted tokens instead of manual entry */}
              {mode !== PoolModalMode.Init && (
                <>
                  <FormLabel>Pool Address</FormLabel>
                  <Input
                    disabled
                    value={pool?.address || poolAddress?.publicKey.toBase58() || ""}
                  />
                </>
              )}
              <>
                <FormLabel>Token A</FormLabel>
                <TokenPicker
                  token={tokenA ?? pool?.tokenA}
                  otherToken={tokenB ?? pool?.tokenB}
                  disabled={mode !== PoolModalMode.Init}
                  onSelect={setTokenA}
                />
              </>
              <>
                <FormLabel>Token B</FormLabel>
                <TokenPicker
                  token={tokenB ?? pool?.tokenB}
                  otherToken={tokenA ?? pool?.tokenA}
                  disabled={mode !== PoolModalMode.Init}
                  onSelect={setTokenB}
                />
              </>
              {mode === PoolModalMode.Init && (
                <>
                  <FormLabel>Initial Price</FormLabel>
                  <Switch
                    isChecked={poolPriceEntryMode === PoolPriceEntryMode.UsdPrice}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setPoolPriceEntryMode(PoolPriceEntryMode.UsdPrice);
                      } else {
                        setTokenAUSDPriceRaw(undefined);
                        setTokenBUSDPriceRaw(undefined);
                        setPoolPriceEntryMode(PoolPriceEntryMode.Raw);
                      }
                    }}
                  >
                    Enter USD prices for A and B
                  </Switch>
                  {poolPriceEntryMode === PoolPriceEntryMode.Raw && (
                    <Input
                      type="number"
                      value={initialPriceRaw}
                      placeholder="B per A OR (USD price of A / USD price of B)"
                      onChange={(e) => setInitialPriceRaw(e.target.value)}
                    />
                  )}
                  {poolPriceEntryMode === PoolPriceEntryMode.UsdPrice && (
                    <>
                      <Input
                        type="number"
                        value={tokenAUSDPriceRaw}
                        placeholder="USD Price of Token A"
                        onChange={(e) => setTokenAUSDPriceRaw(e.target.value)}
                      />
                      <Input
                        type="number"
                        value={tokenBUSDPriceRaw}
                        placeholder="USD Price of Token B"
                        onChange={(e) => setTokenBUSDPriceRaw(e.target.value)}
                      />
                      {initialPriceRaw && tokenAUSDPriceRaw && tokenBUSDPriceRaw && (
                        <Text>Initial B/A Price: {initialPriceRaw}</Text>
                      )}
                    </>
                  )}
                  {whirlpoolPrice && (
                    <StyledClickableText
                      onClick={() => setInitialPriceRaw(whirlpoolPrice.toString())}
                    >
                      Use auto-fetched price: {whirlpoolPrice.toString()}
                    </StyledClickableText>
                  )}
                  {!whirlpoolPrice && whirlpoolPriceLoading && (
                    <StyledText opacity="0.6">Trying to auto-fetch price...</StyledText>
                  )}
                </>
              )}
              <>
                <FormLabel>TickSpacing</FormLabel>
                <Input
                  disabled={mode !== PoolModalMode.Init}
                  type="number"
                  value={pool?.tickSpacing ?? tickSpacing}
                  placeholder="Tick spacing"
                  onChange={(e) => setTickSpacing(parseInt(e.target.value))}
                />
              </>
              {mode === PoolModalMode.View && (
                <>
                  <FormLabel>Both Tokens Whitelisted</FormLabel>
                  <Checkbox
                    _hover={{
                      cursor: "not-allowed",
                    }}
                    colorScheme="green"
                    isChecked={whitelisted}
                  />
                </>
              )}
            </VStack>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          {mode === PoolModalMode.Init && (
            <>
              <Button
                disabled={!readyToSubmit || loading}
                colorScheme="teal"
                mr="20px"
                onClick={handlePrimaryClick}
              >
                {!loading ? phantom ? "Init" : "Connect Phantom" : <Spinner />}
              </Button>
              <Button onClick={handleOnClose}>Cancel</Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
