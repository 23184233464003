import { useCallback, useEffect, useState } from "react";
import { TokenDto, useTokensAPI } from "../../hooks/useTokensAPI";

export interface WhitelistedTokensHookOutput {
  loading: boolean;
  tokens: TokenDto[];
  reload(): Promise<void>;
  handleFilter(filter?: string): void;
}

export function useWhitelistedTokens(): WhitelistedTokensHookOutput {
  const [loading, setLoading] = useState<boolean>(false);
  const [tokens, setTokens] = useState<TokenDto[]>([]);
  const [filteredTokens, setFilteredTokens] = useState<TokenDto[]>();
  const tokensAPI = useTokensAPI();

  const loadTokens = useCallback(async () => {
    const whitelistedTokens = await tokensAPI.listWhitelistedTokens();
    setTokens(whitelistedTokens);
  }, [tokensAPI]);

  useEffect(() => {
    setLoading(true);
    loadTokens().then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = useCallback(
    (filter?: string) => {
      setFilteredTokens(
        filter
          ? tokens.filter(
              (token) =>
                token.symbol.toLowerCase().includes(filter.toLowerCase()) ||
                token.name.toLowerCase().includes(filter.toLowerCase()) ||
                token.mint.includes(filter)
            )
          : undefined
      );
    },
    [tokens, setFilteredTokens]
  );

  return {
    tokens: filteredTokens ?? tokens,
    loading,
    reload: loadTokens,
    handleFilter,
  };
}
