import { useCallback, useContext, useMemo } from "react";
import { Auth, AuthContext } from "../contexts/auth";
import { getBaseAPIUrl } from "../utils/api";

/**
 * Right now, the DTO types/interfaces in this file are duplicated from commons/src/api/token.ts since we can't share that code with the Poolops UI app due to
 * it not being a part of the lerna setup.
 * There is a pending task to resolve this issue: https://app.asana.com/0/1202413563699470/1202606531127077/f
 */

export interface TokenDto {
  mint: string;
  name: string;
  symbol: string;
  decimals: number;
  logoURI?: string;
  coingeckoId?: string;
  whitelisted: boolean;
  poolToken: boolean;
  wrapper?: string;
}

export interface ListTokenParams {
  searchString?: string;
  whitelisted?: boolean;
}

export interface ListTokenResponse {
  tokens: TokenDto[];
}

export interface MutateTokenRequest {
  mint: string;
  name?: string;
  symbol?: string;
  logoURI?: string;
  coingeckoId?: string;
  whitelisted?: boolean;
}

export interface TokenAPI {
  listWhitelistedTokens(): Promise<TokenDto[]>;
  listAllTokens(searchString?: string): Promise<TokenDto[]>;
  getToken(mint: string): Promise<TokenDto | undefined>;
}

export function useTokensAPI(): TokenAPI {
  const baseAPIUrl = getBaseAPIUrl();
  const auth = useContext(AuthContext);

  const getToken = useCallback(
    async (mint: string) => {
      const res = await fetch(`${baseAPIUrl}/token/${mint}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: validateAuth(auth).accessToken,
        },
      });
      if (res.status === 404) {
        return undefined;
      }
      if (!res.ok) {
        throw new Error(`Failed to get token: ${res.status}`);
      }
      return res.json();
    },
    [auth, baseAPIUrl]
  );

  const listWhitelistedTokens = useCallback(async () => {
    const res = await fetch(`${baseAPIUrl}/token/list?whitelisted=true`, {
      method: "GET",
      headers: {
        authorization: validateAuth(auth).accessToken,
      },
    });
    const json: ListTokenResponse = await res.json();
    return json.tokens;
  }, [auth, baseAPIUrl]);

  const listAllTokens = useCallback(
    async (searchString?: string) => {
      const queryParams = searchString ? `searchString=${searchString}` : "";
      const res = await fetch(`${baseAPIUrl}/token/list?limit=10&${queryParams}`, {
        method: "GET",
        headers: {
          authorization: validateAuth(auth).accessToken,
        },
      });
      const json: ListTokenResponse = await res.json();
      return json.tokens;
    },
    [auth, baseAPIUrl]
  );

  return useMemo(
    () => ({
      getToken,
      listWhitelistedTokens,
      listAllTokens,
    }),
    [getToken, listWhitelistedTokens, listAllTokens]
  );
}

function validateAuth(auth: Auth | undefined): Auth {
  if (auth === undefined) {
    throw new Error("Auth Token is undefined");
  }

  return auth;
}
