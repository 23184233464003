import { AnchorProvider } from "@coral-xyz/anchor";
import { Wallet } from "@orca-so/common-sdk";
import { Connection } from "@solana/web3.js";
import { NETWORK_RPC_URL } from "../utils/network";

export function useProvider(wallet: Wallet | undefined): AnchorProvider | undefined {
  if (!wallet) {
    return undefined;
  }

  return new AnchorProvider(new Connection(NETWORK_RPC_URL, "recent"), wallet, {
    skipPreflight: false,
    commitment: "processed",
    preflightCommitment: "processed",
  });
}
