import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Spinner,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { AddressUtil } from "@orca-so/common-sdk";
import { InitializeRewardParams, ORCA_WHIRLPOOLS_CONFIG, ORCA_WHIRLPOOL_PROGRAM_ID, PDAUtil, toTx, WhirlpoolIx } from "@orca-so/whirlpools-sdk";
import { Keypair } from "@solana/web3.js";
import { useMemo } from "react";
import { useState } from "react";
import { PoolPicker } from "../../components/PoolPicker";
import { TokenPicker } from "../../components/TokenPicker";
import { useNetwork } from "../../hooks/useNetwork";
import { useProvider } from "../../hooks/useProvider";
import { TokenDto } from "../../hooks/useTokensAPI";
import { usePhantom } from "../../hooks/useWallet";
import { useWhirlpool } from "../../hooks/useWhirlpool";
import { WhirlpoolDto } from "../../hooks/useWhirlpoolAPI";
import { useWhirlpoolClient } from "../../hooks/useWhirlpoolClient";
import { isValidPubkeyStr } from "../../utils/pubkey";
import { executeTx } from "../../utils/transaction";
import { makeBlockExplorerUrl } from "../../utils/tx";

function useInitReward(): (params: InitializeRewardParams) => Promise<void> {
  const client = useWhirlpoolClient();
  const network = useNetwork();
  const toast = useToast();
  const phantom = usePhantom();
  const provider = useProvider(phantom);

  return async (params) => {
    let txUrl: string;

    try {
      if (!provider) throw new Error("Provider not loaded");
      if (!client) throw new Error("Whirlpool client not loaded");

      // memo: V2 can be used as V1
      const rewardTokenBadgePda = PDAUtil.getTokenBadge(ORCA_WHIRLPOOL_PROGRAM_ID, ORCA_WHIRLPOOLS_CONFIG, params.rewardMint);
      const rewardMintWithProgram = await client.getFetcher().getMintInfo(params.rewardMint);
      const tx = toTx(client.getContext(), WhirlpoolIx.initializeRewardV2Ix(client.getContext().program, {
        ...params,
        rewardTokenBadge: rewardTokenBadgePda.publicKey,
        rewardTokenProgram: rewardMintWithProgram.tokenProgram,
      })); // initializeRewardV2Ix will add signer for rewardVaultKeypair
      const txHash = await executeTx(provider, tx);

      txUrl = makeBlockExplorerUrl(network, txHash);
      toast({
        position: "top-right",
        title: "Init Reward Successful",
        description: (
          <Link href={txUrl} isExternal>
            View on Solscan
          </Link>
        ),
        status: "success",
        duration: 4_000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        position: "top-right",
        title: "Init Reward Failed",
        description: (err as Error).message,
        status: "error",
        duration: 4_000,
        isClosable: true,
      });
    }
  };
}

const PLACEHOLDER_REWARD_MINT = "11111111111111111111111111111111";
const MAX_REWARD_SLOTS = 3;

export function InitReward() {
  const [pool, setPool] = useState<WhirlpoolDto>();
  const [rewardToken, setRewardToken] = useState<TokenDto>();

  const phantom = usePhantom();
  const provider = useProvider(phantom);
  const whirlpool = useWhirlpool(pool?.address);
  const initReward = useInitReward();
  const [loading, setLoading] = useState(false);

  const nextRewardIndex = useMemo(() => {
    if (!whirlpool) return undefined;

    return (
      whirlpool.getRewardInfos().findIndex((reward) => reward.mint.toBase58() === PLACEHOLDER_REWARD_MINT) ??
      undefined
    );
  }, [whirlpool]);

  const slotsLeft = nextRewardIndex != null && nextRewardIndex < MAX_REWARD_SLOTS;
  const readyToSubmit =
    pool &&
    isValidPubkeyStr(pool.address) &&
    phantom?.publicKey &&
    provider &&
    whirlpool &&
    rewardToken &&
    isValidPubkeyStr(rewardToken.mint) &&
    nextRewardIndex != null;

  async function handleSubmit() {
    if (!readyToSubmit || !rewardToken || !pool) return;

    setLoading(true);
    const params: InitializeRewardParams = {
      funder: phantom.publicKey,
      rewardAuthority: phantom.publicKey,
      rewardIndex: nextRewardIndex,
      rewardMint: AddressUtil.toPubKey(rewardToken.mint),
      whirlpool: AddressUtil.toPubKey(pool.address),
      rewardVaultKeypair: Keypair.generate(),
    };

    try {
      await initReward(params);
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }

  return (
    <Center w="40%" minW="300px">
      <FormControl w="100%">
        <VStack spacing="30px" alignItems="start">
          <VStack alignItems="start" w="100%">
            <FormLabel>Pool</FormLabel>
            <PoolPicker pool={pool} onSelect={setPool} />
          </VStack>
          <VStack alignItems="start" w="100%">
            <FormLabel>Reward Token</FormLabel>
            <TokenPicker token={rewardToken} onSelect={setRewardToken} />
          </VStack>
          <Box w="100%">
            <FormLabel>Reward Authority</FormLabel>
            <Input disabled value={phantom?.publicKey?.toBase58?.() ?? "CONNECT WALLET TO FILL"} />
          </Box>
          <Box w="100%">
            <FormLabel>Reward Index</FormLabel>
            <Input disabled value={nextRewardIndex ?? "ENTER POOL ADDR ABOVE TO FILL"} />
          </Box>

          <Flex flexDir="column" alignItems="end" w="100%">
            <Button
              w="30%"
              py="20px"
              colorScheme="teal"
              mt="20px"
              disabled={!whirlpool || !slotsLeft || !readyToSubmit || loading}
              minW="200px"
              onClick={handleSubmit}
            >
              {loading ? (
                <Spinner />
              ) : whirlpool ? (
                slotsLeft ? (
                  "Initialize Rewards"
                ) : (
                  "No slots remaining"
                )
              ) : isValidPubkeyStr(pool?.address) ? (
                "Loading whirlpool..."
              ) : (
                "Enter pool address"
              )}
            </Button>
          </Flex>
        </VStack>
      </FormControl>
    </Center>
  );
}
