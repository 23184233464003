import { useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { PhantomConnectButton } from "./components/PhantomConnectButton";
import { Phantom } from "./types/wallet";
import { displayPubkey } from "./utils/pubkey";
import { Header } from "./components/Header";
import { Home } from "./pages/Home";
import { Page } from "./components/Page";
import { Tokens } from "./pages/Tokens";
import { PhantomContext } from "./contexts/phantom";
import { NetworkContext } from "./contexts/network";
import { Pools } from "./pages/Pools";
import { NetworkIndicator } from "./components/NetworkIndicator";
import { HomeButton } from "./components/HomeButton";
import { PoolConfigs } from "./pages/PoolConfigs";
import { Rewards } from "./pages/Rewards";
import { Fees } from "./pages/Fees";
import { InitReward } from "./pages/Rewards/InitReward";
import { SetRewardEmissions } from "./pages/Rewards/SetRewardEmissions";
import { SetRewardAuthority } from "./pages/Rewards/SetRewardAuthority";
import { SetRewardSuperAuthority } from "./pages/Rewards/SetRewardSuperAuthority";
import { SetFeeRate } from "./pages/Fees/SetFeeRate";
import { SetProtocolFeeRate } from "./pages/Fees/SetProtocolFeeRate";
import { CollectProtocolFees } from "./pages/Fees/CollectProtocolFees";
import { Auth } from "./pages/Auth";
import { AuthProvider } from "./contexts/auth";
import { LogoutButton } from "./components/LogoutButton";
import { unsafeBypassAuthentication } from "./utils/env";
import { SetRewardAuthorityBySuperAuth } from "./pages/Rewards/SetRewardAuthorityBySuperAuth";
import { NETWORK_NAME } from "./utils/network";
import { ViewRewards } from "./pages/Rewards/ViewRewards";
import { WhirlpoolContextProvider } from "./contexts/whirlpool";
import { RewardsRefillInbox } from "./pages/Rewards/RewardsRefillInbox";

function App() {
  const toast = useToast();
  const [phantom, setPhantom] = useState<Phantom>();

  return (
    <div className="App">
      <AuthProvider>
        <NetworkContext.Provider value={NETWORK_NAME}>
          <PhantomContext.Provider value={phantom}>
            <WhirlpoolContextProvider>
              <Header
                childrenLeft={
                  <HStack spacing="20px">
                    <HomeButton />
                  </HStack>
                }
                childrenRight={
                  <HStack spacing="20px">
                    {unsafeBypassAuthentication() || <LogoutButton />}
                    <NetworkIndicator />
                    <VStack>
                      <PhantomConnectButton
                        isConnected={!!phantom}
                        onConnect={setPhantom}
                        onDisconnect={() => setPhantom(undefined)}
                        onError={(error: Error) => {
                          console.error(error);
                          toast({
                            position: "top-right",
                            title: "Phantom connection error",
                            description: error.message,
                            status: "error",
                            duration: 4_000,
                            isClosable: true,
                          });
                        }}
                      />
                      <div style={{ position: "absolute", marginTop: "40px" }}>
                        {phantom ? (
                          <Text fontSize="14px" mt="6px" color="whiteAlpha.600">
                            {displayPubkey(phantom.publicKey.toString())}
                          </Text>
                        ) : (
                          <Text fontSize="14px" mt="6px" color="transparent">
                            Not Connected
                          </Text>
                        )}
                      </div>
                    </VStack>
                  </HStack>
                }
              />
              <Page>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/auth" element={<Auth />} />
                  <Route path="/tokens" element={<Tokens />} />
                  <Route path="/pools" element={<Pools />} />
                  <Route path="/configs" element={<PoolConfigs />} />
                  <Route path="/rewards" element={<Rewards />} />
                  <Route path="/rewards/view" element={<ViewRewards />} />
                  <Route path="/rewards/refill/inbox" element={<RewardsRefillInbox />} />
                  <Route path="/rewards/initialize" element={<InitReward />} />
                  <Route path="/rewards/emissions" element={<SetRewardEmissions />} />
                  <Route path="/rewards/authority" element={<SetRewardAuthority />} />
                  <Route path="/rewards/super-authority" element={<SetRewardSuperAuthority />} />
                  <Route
                    path="/rewards/authority-by-superauth"
                    element={<SetRewardAuthorityBySuperAuth />}
                  />
                  <Route path="/fees" element={<Fees />} />
                  <Route path="/fees/feeRate" element={<SetFeeRate />} />
                  <Route path="/fees/protocolFeeRate" element={<SetProtocolFeeRate />} />
                  <Route path="/fees/collect" element={<CollectProtocolFees />} />
                </Routes>
              </Page>
            </WhirlpoolContextProvider>
          </PhantomContext.Provider>
        </NetworkContext.Provider>
      </AuthProvider>
    </div>
  );
}

export default App;
