import { Auth } from "../contexts/auth";

export function getBaseAPIUrl(): string {
  const baseAPIUrl = process.env.REACT_APP_POOLOPS_API_URL;

  if (!baseAPIUrl) {
    throw new Error("REACT_APP_POOLOPS_API_URL is not defined");
  }

  return baseAPIUrl;
}

export async function fetchWithAuth(
  input: RequestInfo,
  auth: Auth | undefined,
  init?: RequestInit | undefined
): Promise<Response> {
  const { accessToken } = validateAuth(auth);
  const initWithAuth: RequestInit = init
    ? {
        ...init,
        headers: {
          ...init.headers,
          authorization: validateAuth(auth).accessToken,
        },
      }
    : {
        method: "GET",
        headers: {
          authorization: accessToken,
        },
      };

  return await fetch(input, initWithAuth);
}

export function validateAuth(auth: Auth | undefined): Auth {
  if (auth === undefined) {
    throw new Error("Auth Token is undefined");
  }

  return auth;
}
