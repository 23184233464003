import { SimpleGrid } from "@chakra-ui/react";
import { MenuCard, MenuIcon } from "../components/MenuCard";

export function Home() {
  return (
    <SimpleGrid w="100%" minChildWidth="300px" spacing="80px">
      <MenuCard name="Manage Tokens" route="tokens?tab=whitelisted" icon={MenuIcon.Token} />
      <MenuCard name="Manage Pools" route="pools" icon={MenuIcon.Pool} />
      <MenuCard name="Manage Rewards" route="rewards" icon={MenuIcon.Rewards} />
      <MenuCard name="Manage Fees" route="fees" icon={MenuIcon.Fees} />
    </SimpleGrid>
  );
}
