import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Modal,
  List,
  ListItem,
  HStack,
  Text,
  Checkbox,
  Spinner,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { useAllWhirlpools } from "../hooks/useAllWhirlpools";
import { useTokensMap } from "../hooks/useTokensMap";
import { useWhirlpools } from "../hooks/useWhirlpools";
import { useWhirlpoolClient } from "../hooks/useWhirlpoolClient";
import { DecimalUtil } from "@orca-so/common-sdk";

export function ExportRewardVaults() {
  const exportVaults = useDisclosure();

  return (
    <>
      <Button onClick={exportVaults.onOpen}>Export Reward Vaults</Button>
      <ExportModal isOpen={exportVaults.isOpen} onClose={exportVaults.onClose} />
    </>
  );
}

const StyledTokenIcon = styled.img`
  width: 24px;
  border-radius: 24px;
`;

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function ExportModal({ isOpen, onClose }: ExportModalProps) {
  const [loading, setLoading] = useState(false);
  const { whirlpools } = useAllWhirlpools();
  const tokensMap = useTokensMap();
  const [selectedPools, setSelectedPools] = useState<{ [poolPubkey: string]: boolean }>({});
  const poolsToExport = useMemo(
    () =>
      Object.entries(selectedPools)
        .filter(([, selected]) => selected)
        .map(([pool]) => pool),
    [selectedPools]
  );
  const whirlpoolClient = useWhirlpoolClient();
  const whirlpoolsAccountMap = useWhirlpools(poolsToExport);
  const readyToExport =
    poolsToExport.length > 0 && Object.keys(whirlpoolsAccountMap).length === poolsToExport.length;

  async function handleExport() {
    if (!whirlpoolClient || !whirlpoolsAccountMap) return;

    setLoading(true);

    const poolToRewardVaultsJson = Object.values(whirlpoolsAccountMap).reduce((map, whirlpool) => {
      const [tokenA, tokenB] = [
        tokensMap[whirlpool.getData().tokenMintA.toBase58()],
        tokensMap[whirlpool.getData().tokenMintB.toBase58()],
      ];

      if (!tokenA || !tokenB) {
        return map;
      }

      const key = `${tokenA.symbol}/${tokenB.symbol}`;

      return {
        ...map,
        [key]: whirlpool.getRewardInfos().reduce((rewardVaultMap, reward) => {
          if (!reward.initialized) return rewardVaultMap;

          const rewardToken = tokensMap[reward.mint.toBase58()];

          if (!rewardToken) return rewardVaultMap;

          return {
            ...rewardVaultMap,
            [rewardToken.symbol]: {
              vaultPubkey: reward.vault.toBase58(),
              vaultBalance: DecimalUtil.fromBN(reward.vaultAmount, rewardToken.decimals).toString(),
            },
          };
        }, {}),
      };
    }, {} as RewardVaultJson);

    downloadRewardVaultsJson(poolToRewardVaultsJson);
    setLoading(false);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Export Reward Vaults</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <List>
            {tokensMap &&
              whirlpools.map((whirlpool) => {
                const { tokenA, tokenB } = whirlpool;

                if (!tokenA || !tokenB) return null;

                return (
                  <ListItem key={whirlpool.address}>
                    <HStack my="10px">
                      <Checkbox
                        mr="10px"
                        onChange={(e) =>
                          setSelectedPools((selectedPools) => ({
                            ...selectedPools,
                            [whirlpool.address]: e.target.checked,
                          }))
                        }
                      />
                      <StyledTokenIcon src={tokenA.logoURI} style={{ zIndex: 2 }} />
                      <StyledTokenIcon
                        src={tokenB.logoURI}
                        style={{ position: "relative", left: "-20px" }}
                      />
                      <Text position="relative" left="-10px">
                        {tokenA.symbol}/{tokenB.symbol}
                      </Text>
                    </HStack>
                  </ListItem>
                );
              })}
          </List>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" disabled={loading || !readyToExport} onClick={handleExport}>
            {loading || (!readyToExport && poolsToExport.length > 0) ? (
              <Spinner />
            ) : (
              <Text>Export Reward Vaults</Text>
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface RewardVaultJson {
  [poolPair: string]: {
    [rewardTokenSymbol: string]: {
      vaultPubkey: string;
      vaultBalance: string;
    };
  };
}

const downloadRewardVaultsJson = (data: RewardVaultJson) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], { type: "text/json" });

  const a = document.createElement("a");
  a.download = "reward-vaults.json";
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};
