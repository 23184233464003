import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import TokenIcon from "../assets/token.png";
import PoolIcon from "../assets/pool.png";
import ConfigIcon from "../assets/pool-config.png";
import RewardsIcon from "../assets/rewards.png";
import FeesIcon from "../assets/fees.png";
import InitRewardIcon from "../assets/init-reward.png";
import EmissionsIcon from "../assets/emissions.png";
import AuthorityIcon from "../assets/authority.png";
import SuperAuthorityIcon from "../assets/super-authority.png";
import FeeRate from "../assets/feeRate.png";
import ProtocolFeeRate from "../assets/protocolFeeRate.png";
import CollectFees from "../assets/collectFees.png";
import LowBattery from "../assets/low-battery.png";

const StyledContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledCardContainer = styled.div`
  height: 100%;
  width: 300px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #35373b;
  border-radius: 30px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  transition: 0.5s ease;

  &:hover {
    cursor: pointer;
    transform: translate(0%, -2%);
    color: rgba(255, 255, 255, 1);
    transition: 0.5s ease;
  }
`;

const StyledNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledIcon = styled.img`
  width: 70px;
  margin-bottom: 28px;
`;

const StyledTitle = styled.div`
  text-align: center;
  font-size: 25px;
`;

export enum MenuIcon {
  Token,
  Pool,
  Config,
  Rewards,
  Fees,
  ViewRewards,
  InitReward,
  SetEmissions,
  SetRewardsAuthority,
  SetRewardsAuthorityBySuAuth,
  FeeRate,
  ProtocolFeeRate,
  CollectFees,
  RewardRefillInbox,
}

type Props = {
  name: string;
  route: string;
  icon: MenuIcon;
};

function renderMenuIcon(icon: MenuIcon) {
  switch (icon) {
    case MenuIcon.ViewRewards:
      return <StyledIcon src={TokenIcon} />;
    case MenuIcon.RewardRefillInbox:
      return <StyledIcon src={LowBattery} />;
    case MenuIcon.Token:
      return <StyledIcon src={TokenIcon} />;
    case MenuIcon.Pool:
      return <StyledIcon src={PoolIcon} />;
    case MenuIcon.Config:
      return <StyledIcon src={ConfigIcon} />;
    case MenuIcon.Rewards:
      return <StyledIcon src={RewardsIcon} />;
    case MenuIcon.Fees:
      return <StyledIcon src={FeesIcon} />;
    case MenuIcon.InitReward:
      return <StyledIcon src={InitRewardIcon} />;
    case MenuIcon.SetEmissions:
      return <StyledIcon src={EmissionsIcon} />;
    case MenuIcon.SetRewardsAuthority:
      return <StyledIcon src={AuthorityIcon} />;
    case MenuIcon.SetRewardsAuthorityBySuAuth:
      return <StyledIcon src={SuperAuthorityIcon} />;
    case MenuIcon.FeeRate:
      return <StyledIcon src={FeeRate} />;
    case MenuIcon.ProtocolFeeRate:
      return <StyledIcon src={ProtocolFeeRate} />;
    case MenuIcon.CollectFees:
      return <StyledIcon src={CollectFees} />;
  }
}

export function MenuCard({ name, route, icon }: Props) {
  return (
    <StyledContainer>
      <StyledCardContainer>
        <Link to={route}>
          <StyledNameContainer>
            {renderMenuIcon(icon)}
            <StyledTitle>{name}</StyledTitle>
          </StyledNameContainer>
        </Link>
      </StyledCardContainer>
    </StyledContainer>
  );
}
