import { Address } from "@orca-so/common-sdk";
import { PublicKey } from "@solana/web3.js";

const CHARACTERS_VISIBLE = 8;

export function displayPubkey(pubkeyInput: string | PublicKey): string {
  const pubkey = pubkeyInput.toString();

  return `${pubkey.slice(0, CHARACTERS_VISIBLE / 2)}...${pubkey.slice(
    pubkey.length - CHARACTERS_VISIBLE / 2,
    pubkey.length
  )}`;
}

export function isValidPubkeyStr(pubkey: unknown): pubkey is Address & string {
  if (typeof pubkey !== "string") {
    return false;
  }

  try {
    return new PublicKey(pubkey).toBase58().toLowerCase() === pubkey.toLowerCase();
  } catch {
    return false;
  }
}
