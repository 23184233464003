import { Center, useInterval } from "@chakra-ui/react";
import React, { createContext, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SignInButton } from "../components/SignInButton";
import { getBaseAPIUrl } from "../utils/api";
import { unsafeBypassAuthentication } from "../utils/env";

export interface Auth {
  idToken: string;
  accessToken: string;
  logout: () => void;
}

export const AuthContext = createContext<Auth | undefined>(undefined);

const LOCAL_TOKEN: Auth = {
  idToken: "idToken",
  accessToken: "accessToken",
  logout: () => {},
};

export interface Props {
  children: ReactNode;
}

export function AuthProvider({ children }: Props) {
  const [savedAuth, setSavedAuth] = useState<Auth>();
  const { idToken, accessToken } = useAuthTokensFromLocation() || savedAuth || {};

  useEffect(() => {
    if (!idToken || !accessToken) return;

    setSavedAuth({
      idToken,
      accessToken,
      logout: () => {
        setSavedAuth(undefined);
      },
    });
  }, [idToken, accessToken]);

  useInterval(() => {
    if (!savedAuth || unsafeBypassAuthentication()) return;

    isAuthTokenStillValid(savedAuth.accessToken).then((isValid) => {
      if (!isValid) {
        // We disable this for now because it's annoying to have to re-login every time the validation fails accidentally.
        console.log("Auth token may be invalid, but ignoring for now. If page doesn't work, try refreshing");
        return;
        /*
        console.log("Auth token invalid, logging out...");
        setSavedAuth(undefined);
        return;
        */
      }
      console.log("Auth token still valid");
    });
  }, 5000);

  if (!unsafeBypassAuthentication() && !savedAuth) {
    return (
      <Center h="100vh">
        <SignInButton />
      </Center>
    );
  }

  return (
    <AuthContext.Provider value={unsafeBypassAuthentication() ? LOCAL_TOKEN : savedAuth}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuthTokensFromLocation():
  | {
      accessToken: string;
      idToken: string;
    }
  | undefined {
  const location = useLocation();
  const { pathname, hash } = location;

  if (pathname !== "/auth") {
    return undefined;
  }

  try {
    const paramStrs = hash.slice(1).split("&");

    const { id_token: idToken, access_token: accessToken } = paramStrs.reduce(
      (record, paramStr) => ({
        ...record,
        [paramStr.split("=")[0]]: paramStr.split("=")[1],
      }),
      {} as Record<string, string>
    );

    return {
      idToken,
      accessToken,
    };
  } catch {
    return undefined;
  }
}

async function isAuthTokenStillValid(token: string): Promise<boolean> {
  const baseAPIUrl = getBaseAPIUrl();
  try {
    const res = await fetch(`${baseAPIUrl}/status`, {
      method: "GET",
      headers: {
        authorization: token,
      },
    });

    return res.status === 200;
  } catch {
    return false;
  }
}
