import {
  Text,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import styled from "styled-components";
import { TokenDto } from "../hooks/useTokensAPI";
import { displayPubkey } from "../utils/pubkey";

const StyledTokenLogo = styled.img`
  width: 22px;
  border-radius: 22px;
`;

type Props = {
  isOpen: boolean;
  onClose: (shouldRefresh: boolean) => unknown;
  token?: TokenDto;
};

export function TokenModal({ isOpen, onClose, token }: Props) {
  function handleOnClose(shouldRefresh: boolean) {
    onClose(shouldRefresh);
  }

  if (!token) return null;

  return (
    <Modal isOpen={isOpen} onClose={() => handleOnClose(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`${token.symbol} (${displayPubkey(token.mint)})`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Box>
              <FormLabel>Coingecko ID</FormLabel>
              <Input disabled value={token.coingeckoId} />
            </Box>
            <Box mt="10px">
              <FormLabel>Mint</FormLabel>
              <Input disabled value={token.mint} />
            </Box>
            <Box mt="10px">
              <FormLabel>Name</FormLabel>
              <Input disabled value={token.name} />
            </Box>
            <Box mt="10px">
              <FormLabel>Symbol</FormLabel>
              <Input disabled value={token.symbol} />
            </Box>
            <Box mt="10px">
              <FormLabel>
                <HStack>
                  <Text>Logo URI</Text>
                  <StyledTokenLogo src={token.logoURI} />
                </HStack>
              </FormLabel>
              <Input type="url" disabled value={token.logoURI} />
            </Box>
            <Box mt="10px">
              <FormLabel>Whitelisted</FormLabel>
              <Checkbox colorScheme="green" disabled isChecked={token.whitelisted} />
            </Box>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleOnClose(false)}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
