import { OrcaNetwork } from "./network";

export function makeBlockExplorerUrl(network: OrcaNetwork, txId: string): string {
  switch (network) {
    case OrcaNetwork.MAINNET:
      return `https://solscan.io/tx/${txId}`;
    case OrcaNetwork.DEVNET:
      return `https://solscan.io/tx/${txId}?cluster=devnet`;
  }
}
