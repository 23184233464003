import { Box, HStack, Spinner } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { TokensTable } from "./TokensTable";
import { useAllTokens } from "./useAllTokens";
import { useWhitelistedTokens } from "./useWhitelistedTokens";

enum TokensTabs {
  Whitelisted,
  All,
}

export function Tokens() {
  const [searchParams] = useSearchParams();
  const currentTab = useMemo(() => getTabFromSearchParam(searchParams.get("tab")), [searchParams]);

  const whitelistedTokens = useWhitelistedTokens();
  const allTokens = useAllTokens();

  useEffect(() => {
    if (currentTab === TokensTabs.Whitelisted) {
      whitelistedTokens.reload();
    }

    if (currentTab === TokensTabs.All) {
      allTokens.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  return (
    <>
      <HStack w="1200px" spacing="30px" fontWeight="bold">
        <TokenTab tab={TokensTabs.Whitelisted} currentTab={currentTab} />
        <TokenTab tab={TokensTabs.All} currentTab={currentTab} />
      </HStack>
      <Box height="30px" />
      {currentTab === TokensTabs.Whitelisted &&
        (whitelistedTokens.loading ? (
          <Box p="20px" w="100%" justifyContent="center" alignItems="center">
            <Spinner />
          </Box>
        ) : (
          <TokensTable
            tokens={whitelistedTokens.tokens}
            refreshTokens={whitelistedTokens.reload}
            onFilter={whitelistedTokens.handleFilter}
          />
        ))}
      {currentTab === TokensTabs.All &&
        (allTokens.loading ? (
          <Box p="20px" w="100%" justifyContent="center" alignItems="center">
            <Spinner />
          </Box>
        ) : (
          <TokensTable
            tokens={allTokens.tokens}
            refreshTokens={allTokens.reload}
            onFilter={allTokens.handleFilter}
          />
        ))}
    </>
  );
}

interface TokenTabProps {
  tab: TokensTabs;
  currentTab: TokensTabs;
}

function TokenTab({ tab, currentTab }: TokenTabProps) {
  const searchParam = `tab=${getSearchParamFromTab(tab)}`;
  const isSelected = currentTab === tab;

  return (
    <Link
      style={{ opacity: isSelected ? "1" : "0.5", fontSize: "24px" }}
      to={`/tokens?${searchParam}`}
    >
      {getLabelFromTab(tab)}
    </Link>
  );
}

function getTabFromSearchParam(searchParam: string | null): TokensTabs {
  switch (searchParam) {
    case "all":
      return TokensTabs.All;
    default:
      return TokensTabs.Whitelisted;
  }
}

function getSearchParamFromTab(tab: TokensTabs): string {
  switch (tab) {
    case TokensTabs.All:
      return "all";
    case TokensTabs.Whitelisted:
      return "whitelisted";
  }
}

function getLabelFromTab(tab: TokensTabs): string {
  switch (tab) {
    case TokensTabs.All:
      return "All";
    case TokensTabs.Whitelisted:
      return "Whitelisted";
  }
}
