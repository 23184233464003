import { Button } from "@chakra-ui/react";
import { useContext } from "react";
import { AuthContext } from "../contexts/auth";

export function LogoutButton() {
  const auth = useContext(AuthContext);

  if (!auth) {
    return null;
  }

  return (
    <Button w="150px" onClick={auth.logout}>
      Logout
    </Button>
  );
}
