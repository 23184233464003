import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Spinner,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { AddressUtil } from "@orca-so/common-sdk";
import { ORCA_WHIRLPOOLS_CONFIG, SetRewardEmissionsSuperAuthorityParams, toTx, WhirlpoolIx } from "@orca-so/whirlpools-sdk";
import { useState } from "react";
import { useNetwork } from "../../hooks/useNetwork";
import { useProvider } from "../../hooks/useProvider";
import { usePhantom } from "../../hooks/useWallet";
import { useWhirlpoolClient } from "../../hooks/useWhirlpoolClient";
import { executeTx } from "../../utils/transaction";
import { makeBlockExplorerUrl } from "../../utils/tx";

function useSetRewardSuperAuthority(): (params: SetRewardEmissionsSuperAuthorityParams) => Promise<void> {
  const client = useWhirlpoolClient();
  const network = useNetwork();
  const phantom = usePhantom();
  const provider = useProvider(phantom);
  const toast = useToast();

  return async (params) => {
    let txUrl: string;

    try {
      if (!provider) throw new Error("Provider not loaded");
      if (!client) throw new Error("Whirlpool client not loaded");

      const tx = toTx(client.getContext(), WhirlpoolIx.setRewardEmissionsSuperAuthorityIx(client.getContext().program, params));
      const txHash = await executeTx(provider, tx);

      txUrl = makeBlockExplorerUrl(network, txHash);
      toast({
        position: "top-right",
        title: "Set Reward Super Authority Successful",
        description: (
          <Link href={txUrl} isExternal>
            View on Solscan
          </Link>
        ),
        status: "success",
        duration: 4_000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        position: "top-right",
        title: "Set Reward Super Authority Failed",
        description: (err as Error).message,
        status: "error",
        duration: 4_000,
        isClosable: true,
      });
    }
  };
}

export function SetRewardSuperAuthority() {
  const phantom = usePhantom();
  const provider = useProvider(phantom);
  const [newRewardSuperAuthority, setNewRewardSuperAuthority] = useState<string>();
  const [loading, setLoading] = useState(false);

  const isReadyToSubmit = provider && phantom && newRewardSuperAuthority;

  const setRewardSuperAuthority = useSetRewardSuperAuthority();

  async function handleSubmit() {
    if (!isReadyToSubmit) return;

    setLoading(true);

    try {
      await setRewardSuperAuthority({
        whirlpoolsConfig: ORCA_WHIRLPOOLS_CONFIG,
        rewardEmissionsSuperAuthority: provider.publicKey,
        newRewardEmissionsSuperAuthority: AddressUtil.toPubKey(newRewardSuperAuthority),
      });
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }

  return (
    <Center w="40%" minW="300px">
      <FormControl w="100%">
        <VStack spacing="30px" alignItems="start">
          <Box w="100%">
            <FormLabel>New Reward Super Authority</FormLabel>
            <Input
              placeholder="Enter new reward super authority pubkey"
              value={newRewardSuperAuthority}
              onChange={(e) => setNewRewardSuperAuthority(e.target.value)}
            />
          </Box>

          <Flex flexDir="column" alignItems="end" w="100%">
            <Button
              w="40%"
              py="20px"
              colorScheme="teal"
              mt="20px"
              disabled={!isReadyToSubmit || !phantom || !setRewardSuperAuthority || loading}
              minW="200px"
              onClick={handleSubmit}
            >
              {loading ? (
                <Spinner />
              ) : phantom ? (
                isReadyToSubmit ? (
                  "Set Reward Super Authority"
                ) : (
                  "Enter Details"
                )
              ) : (
                "Connect Phantom"
              )}
            </Button>
          </Flex>
        </VStack>
      </FormControl>
    </Center>
  );
}
