import {
  Text,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Code,
  Button,
  Spinner,
  Box,
  VStack,
} from "@chakra-ui/react";
import { DecimalUtil } from "@orca-so/common-sdk";
import { RewardToRefillDto, RewardVaultStatusDto } from "@orca-so/orca-commons";
import { BN } from "@coral-xyz/anchor";
import Decimal from "decimal.js";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useAllWhirlpools } from "../../../hooks/useAllWhirlpools";
import { TokenDto } from "../../../hooks/useTokensAPI";
import { useTokensMap } from "../../../hooks/useTokensMap";
import { WhirlpoolDto } from "../../../hooks/useWhirlpoolAPI";
import { displayPubkey } from "../../../utils/pubkey";

const StyledTr = styled(Tr)`
  transition: 0.2s ease;
  font-size: 12px;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.05);
    transition: 0.2s ease;
  }
`;

const StyledTokenIcon = styled.img`
  width: 24px;
  border-radius: 24px;
`;

const StyledCode = styled(Code)`
  font-size: 10px !important;
`;

export interface RewardsRefillTableProps {
  rewardsToRefill: RewardToRefillDto[];
  onArchive: (whirpool: string, rewardIndex: 0 | 1 | 2) => Promise<unknown>;
}

export const RewardsRefillTable: React.FC<RewardsRefillTableProps> = ({
  rewardsToRefill,
  onArchive,
}) => {
  const tokensMap = useTokensMap();
  const { whirlpools } = useAllWhirlpools();
  const whirlpoolsMap = useMemo(
    () =>
      whirlpools.reduce((map, whirlpool) => {
        map[whirlpool.address] = whirlpool;
        return map;
      }, {} as Partial<Record<string, WhirlpoolDto>>),
    [whirlpools]
  );

  return (
    <Table size="md">
      <Thead>
        <Tr>
          <Th>{"Whirlpool Reward"}</Th>
          <Th>Reward Vault</Th>
          <Th>Balance</Th>
          <Th>Owed</Th>
          <Th>{"Surplus (Balance - Owed)"}</Th>
          <Th>Status</Th>
          <Th>Daily Emissions</Th>
          <Th>{"Days Left (Surplus ÷ Daily Emissions)"}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {rewardsToRefill.map((rewardToRefill) => (
          <RewardRefillTableRow
            onArchive={onArchive}
            rewardToRefill={rewardToRefill}
            tokensMap={tokensMap}
            whirlpoolsMap={whirlpoolsMap}
          />
        ))}
      </Tbody>
    </Table>
  );
};

interface RewardsRefillTableRowProps {
  rewardToRefill: RewardToRefillDto;
  tokensMap: Partial<Record<string, TokenDto>>;
  whirlpoolsMap: Partial<Record<string, WhirlpoolDto>>;
  onArchive: RewardsRefillTableProps["onArchive"];
}

const RewardRefillTableRow: React.FC<RewardsRefillTableRowProps> = ({
  rewardToRefill,
  tokensMap,
  whirlpoolsMap,
  onArchive,
}) => {
  const [archiving, setArchiving] = useState(false);
  const rewardToken = tokensMap[rewardToRefill.mint];
  const whirlpool = whirlpoolsMap[rewardToRefill.whirlpool];
  const vaultBalance = rewardToRefill.vaultBalance.toString();
  const rewardOwed = rewardToRefill.rewardOwed.toString();

  const rewardTokenDisplay = rewardToken ? (
    <HStack>
      <StyledTokenIcon src={rewardToken.logoURI} />
      <Text>{rewardToken.symbol}</Text>
    </HStack>
  ) : (
    <StyledCode>{displayPubkey(rewardToRefill.mint)}</StyledCode>
  );

  const dailyEmissions = new Decimal(rewardToRefill.weeklyEmissions).div(7);
  const surplus = new BN(vaultBalance).sub(new BN(rewardOwed));

  return (
    <StyledTr>
      <Td>
        <VStack alignItems="flex-start">
          {whirlpool ? (
            <>
              <HStack>
                <StyledTokenIcon src={whirlpool.tokenA.logoURI} style={{ zIndex: 2 }} />
                <StyledTokenIcon
                  src={whirlpool.tokenB.logoURI}
                  style={{ position: "relative", left: "-20px" }}
                />
                <Text position="relative" left="-10px">
                  {`${whirlpool.tokenA.symbol}/${whirlpool.tokenB.symbol} ${
                    whirlpool.lpFeeRate ? `(${whirlpool.lpFeeRate * 100}%)` : ""
                  } (reward ${rewardToRefill.index})`}{" "}
                </Text>
              </HStack>
              <Box>{rewardTokenDisplay}</Box>
            </>
          ) : (
            <>
              <StyledCode>
                {rewardToRefill.whirlpool + `(reward ${rewardToRefill.index})`}
              </StyledCode>
              <Box>{rewardTokenDisplay}</Box>
            </>
          )}
        </VStack>
      </Td>
      <Td>
        <StyledCode>{rewardToRefill.vault}</StyledCode>
      </Td>
      <Td>{displayRewardAmount(rewardToken, vaultBalance)}</Td>
      <Td>{displayRewardAmount(rewardToken, rewardOwed)}</Td>
      <Td>{displayRewardAmount(rewardToken, surplus.toString())}</Td>
      <Td>
        <Text>{displayRewardVaultStatus(rewardToRefill.status)}</Text>
        <Button
          onClick={() => {
            setArchiving(true);
            onArchive(rewardToRefill.whirlpool, rewardToRefill.index)
              .then(() => {
                setArchiving(false);
              })
              .catch(() => {
                setArchiving(false);
              });
          }}
          disabled={archiving}
        >
          {archiving ? <Spinner /> : "Archive"}
        </Button>
      </Td>
      <Td>{displayRewardAmount(rewardToken, dailyEmissions.toString())}</Td>
      <Td>
        {dailyEmissions.greaterThan(0)
          ? new Decimal(surplus.toString()).div(dailyEmissions).toDecimalPlaces(2).toString()
          : "N/A"}
      </Td>
    </StyledTr>
  );
};

function displayRewardAmount(
  rewardTokenInfo: { decimals: number; symbol: string } | undefined,
  amount: string
): string | React.ReactNode {
  return rewardTokenInfo ? (
    DecimalUtil.adjustDecimals(new Decimal(amount), rewardTokenInfo.decimals).toString() +
      " " +
      rewardTokenInfo.symbol
  ) : (
    <StyledCode>{amount}</StyledCode>
  );
}

function displayRewardVaultStatus(status: RewardVaultStatusDto): string {
  switch (status) {
    case "buffer_weeks_too_low":
      return "<2 weeks left";
    case "short_of_funds":
      return "Insufficient Funds";
    case "short_of_funds_and_empty":
      return "Empty";
    case "okay":
      return "Okay";
  }
}
