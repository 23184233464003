import React, { useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Code,
  HStack,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { SearchBar } from "../components/SearchBar";
import styled from "styled-components";
import { AddIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { PoolModal, PoolModalMode } from "../components/PoolModal";
import { displayPubkey } from "../utils/pubkey";
import { usePhantom } from "../hooks/useWallet";
import { useProvider } from "../hooks/useProvider";
import { Address } from "@orca-so/common-sdk";
import { ExportRewardVaults } from "../components/ExportRewardVautlts";
import { useAllWhirlpools } from "../hooks/useAllWhirlpools";
import { WhirlpoolDto } from "../hooks/useWhirlpoolAPI";
import { useWhirlpoolClient } from "../hooks/useWhirlpoolClient";
import { IGNORE_CACHE, TickUtil } from "@orca-so/whirlpools-sdk";
import { executeTx } from "../utils/transaction";

const StyledRow = styled(Tr)`
  transition: 0.2s ease;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.05);
    transition: 0.2s ease;
  }
`;

const StyledTokenIcon = styled.img`
  width: 24px;
  border-radius: 24px;
`;

const StyledAddButton = styled(Button)`
  margin-top: 20px !important;
  margin-bottom: 20px;
  padding: 20px;
  transition: 0.2s ease;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.05);
    transition: 0.2s ease;
  }
`;

const StyledAddButton2 = styled(Button)`
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.05);
    transition: 0.2s ease;
  }
`;

export function Pools() {
  const client = useWhirlpoolClient();
  const phantom = usePhantom();
  const provider = useProvider(phantom);
  const { whirlpools, reload: refreshPools, loading, handleFilter } = useAllWhirlpools();
  const [poolsFilter, setPoolsFilter] = useState("");
  const initPool = useDisclosure();
  const editPool = useDisclosure();
  const [selectedPool, setSelectedPool] = useState<WhirlpoolDto>();

  // memo: Old implementation will try to initialize ALL tickarrays if tickarrays for FullRange have been initialized.
  // (I guess no one use this function now)
  // So I updated this function to ensure -2 ~ +2 offset tickarrays are initialized.
  async function initializeNeighboringTickArrays(poolAddress: Address) {
    if (!provider || !client) {
      return null;
    }

    const whirlpool = await client.getPool(poolAddress, IGNORE_CACHE);
    const { tickCurrentIndex, tickSpacing } = whirlpool.getData();
    const startTickIndexes = [-2, -1, 0, 1, 2].map((offset) => {
      return TickUtil.getStartTickIndex(tickCurrentIndex, tickSpacing, offset);
    });
    
    // null: if no need to initialize
    const tx = await whirlpool.initTickArrayForTicks(startTickIndexes);
    if (tx) {
      await executeTx(provider, tx);
    }
  }

  useEffect(() => {
    if (!poolsFilter || poolsFilter.trim() === "") {
      handleFilter(undefined);
    } else {
      handleFilter(poolsFilter);
    }
  }, [handleFilter, poolsFilter]);

  return (
    <>
      <HStack>
        <SearchBar placeholder="Search pools" filter={poolsFilter} onChange={setPoolsFilter} />
        <ExportRewardVaults />
      </HStack>
      <Box height="60px" />
      {whirlpools && whirlpools.length > 0 ? (
        <VStack>
          <Table maxW="800px" size="lg">
            <Thead>
              <Tr>
                <Th>Pair</Th>
                <Th>Token A Mint</Th>
                <Th>Token B Mint</Th>
                <Th>
                  <Center>Tick Spacing</Center>
                </Th>
                <Th>
                  <Center>Fee Rate</Center>
                </Th>
                <Th>
                  <Center>Whitelisted</Center>
                </Th>
                <Th>Initialize TickArrays</Th>
              </Tr>
            </Thead>
            <Tbody>
              {whirlpools.map((pool) => {
                const { tokenA, tokenB } = pool;

                if (!tokenA || !tokenB) {
                  return null;
                }

                return (
                  <StyledRow
                    key={pool.address}
                    onClick={() => {
                      setSelectedPool(pool);
                      editPool.onOpen();
                    }}
                  >
                    <Td>
                      <HStack>
                        <StyledTokenIcon src={tokenA.logoURI} style={{ zIndex: 2 }} />
                        <StyledTokenIcon
                          src={tokenB.logoURI}
                          style={{ position: "relative", left: "-20px" }}
                        />
                        <Text position="relative" left="-10px">
                          {`${tokenA.symbol}/${tokenB.symbol}`}
                        </Text>
                      </HStack>
                    </Td>
                    <Td>
                      <Code colorScheme="gray">{displayPubkey(tokenA.mint)}</Code>
                    </Td>
                    <Td>
                      <Code colorScheme="gray">{displayPubkey(tokenB.mint)}</Code>
                    </Td>
                    <Td>
                      <Center>{pool.tickSpacing}</Center>
                    </Td>
                    <Td>
                      <Center>{pool.lpFeeRate ? `${pool.lpFeeRate * 100}%` : ""}</Center>
                    </Td>
                    <Td>
                      <Center>{pool.whitelisted && <CheckCircleIcon color="#9AE6B4" />}</Center>
                    </Td>
                    <td>
                      <StyledAddButton2
                        disabled={!provider}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.stopPropagation();
                          initializeNeighboringTickArrays(pool.address);
                        }}
                      >
                        <AddIcon />
                      </StyledAddButton2>
                    </td>
                  </StyledRow>
                );
              })}
            </Tbody>
          </Table>
          <PoolModal
            isOpen={editPool.isOpen}
            onClose={() => {
              editPool.onClose();
              refreshPools();
              setSelectedPool(undefined);
            }}
            mode={PoolModalMode.View}
            pool={selectedPool}
          />
        </VStack>
      ) : (
        <Box p="20px" w="100%" justifyContent="center" alignItems="center">
          {loading ? <Spinner /> : <Text>{!!poolsFilter ? "No matches" : "Empty"}</Text>}
        </Box>
      )}
      <StyledAddButton onClick={initPool.onOpen}>
        <AddIcon />
      </StyledAddButton>
      <PoolModal
        isOpen={initPool.isOpen}
        onClose={() => {
          initPool.onClose();
          refreshPools();
          setSelectedPool(undefined);
        }}
        mode={PoolModalMode.Init}
      />
    </>
  );
}
