import React, { ReactNode } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  padding: 0 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

type Props = {
  children: ReactNode;
};

export function Page({ children }: Props) {
  return <StyledContainer>{children}</StyledContainer>;
}
