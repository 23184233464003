import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { TokenDto, useTokensAPI } from "../../hooks/useTokensAPI";

export interface AllTokensHookOutput {
  loading: boolean;
  tokens: TokenDto[];
  reload(): Promise<void>;
  handleFilter(filter?: string): void;
}

export function useAllTokens(): AllTokensHookOutput {
  const [loading, setLoading] = useState<boolean>(false);
  const [tokens, setTokens] = useState<TokenDto[]>([]);
  const [filter, setFilter] = useState<string>();
  const [filteredTokens, setFilteredTokens] = useState<TokenDto[]>();
  const tokensAPI = useTokensAPI();

  const loadTokens = useCallback(async () => {
    const allTokens = await tokensAPI.listAllTokens();
    setTokens(allTokens);
  }, [tokensAPI]);

  useEffect(() => {
    setLoading(true);
    loadTokens().then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = useDebouncedCallback(
    async (filter?: string) => {
      if (!filter) {
        setFilteredTokens(undefined);
        setFilter(undefined);
        return;
      }

      const filteredTokens = await tokensAPI.listAllTokens(filter);
      setFilteredTokens(filteredTokens);
      setFilter(filter);
    },
    500,
    { trailing: true }
  );

  const refreshTokens = useCallback(async () => {
    loadTokens();
    handleFilter(filter);
  }, [filter, handleFilter, loadTokens]);

  return {
    tokens: filteredTokens ?? tokens,
    loading,
    reload: refreshTokens,
    handleFilter,
  };
}
