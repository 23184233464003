import { MarkRewardAsRefilledBody, RewardsToRefillDto } from "@orca-so/orca-commons";
import { useCallback, useContext, useMemo } from "react";
import { AuthContext } from "../contexts/auth";
import { getBaseAPIUrl, validateAuth } from "../utils/api";

export interface RewardsAPI {
  listRewardsToRefill(forceRefresh?: boolean): Promise<RewardsToRefillDto>;
  markRewardAsRefilled(whirlpool: string, rewardIndex: 0 | 1 | 2): Promise<void>;
}

export class RewardsServiceUnavailable extends Error {}

export function useRewardAPI(): RewardsAPI {
  const baseAPIUrl = getBaseAPIUrl();
  const auth = useContext(AuthContext);

  const listRewardsToRefill = useCallback(
    async (bypassCache: boolean = false) => {
      const res = await fetch(`${baseAPIUrl}/reward/refill/list?bypassCache=${bypassCache}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: validateAuth(auth).accessToken,
        },
      });

      if (!res.ok) {
        if (res.status === 503) {
          throw new RewardsServiceUnavailable();
        }

        throw new Error(`Failed to get rewards to refill list: ${res.status}`);
      }

      const data: RewardsToRefillDto = await res.json();
      return data;
    },
    [auth, baseAPIUrl]
  );

  const markRewardAsRefilled = useCallback(
    async (whirlpool: string, rewardIndex: 0 | 1 | 2) => {
      const body: MarkRewardAsRefilledBody = {
        whirlpool,
        rewardIndex,
      };

      const res = await fetch(`${baseAPIUrl}/reward/refill`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          authorization: validateAuth(auth).accessToken,
        },
        body: JSON.stringify(body),
      });

      if (!res.ok) {
        if (res.status === 503) {
          throw new RewardsServiceUnavailable();
        }

        throw new Error(
          `Failed to mark reward as refilled for whirlpool(${whirlpool}) reward index ${rewardIndex}: ${res.status}`
        );
      }
    },
    [auth, baseAPIUrl]
  );

  return useMemo(
    () => ({
      listRewardsToRefill,
      markRewardAsRefilled,
    }),
    [listRewardsToRefill, markRewardAsRefilled]
  );
}
