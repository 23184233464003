import { SimpleGrid } from "@chakra-ui/react";
import { MenuCard, MenuIcon } from "../../components/MenuCard";

export function Rewards() {
  return (
    <SimpleGrid w="100%" minChildWidth="300px" spacing="80px">
      <MenuCard name="Initialize Reward" route="/rewards/initialize" icon={MenuIcon.InitReward} />
      <MenuCard name="View Rewards" route="/rewards/view" icon={MenuIcon.ViewRewards} />
      <MenuCard
        name="Rewards Refill Inbox"
        route="/rewards/refill/inbox"
        icon={MenuIcon.RewardRefillInbox}
      />
      <MenuCard
        name="Set Reward Emissions"
        route="/rewards/emissions"
        icon={MenuIcon.SetEmissions}
      />
      <MenuCard
        name="Set Reward Authority"
        route="/rewards/authority"
        icon={MenuIcon.SetRewardsAuthority}
      />
      <MenuCard
        name="Set Reward Authority by Super Authority"
        route="/rewards/authority-by-superauth"
        icon={MenuIcon.SetRewardsAuthority}
      />
      <MenuCard
        name="Set Reward Super Authority"
        route="/rewards/super-authority"
        icon={MenuIcon.SetRewardsAuthorityBySuAuth}
      />
    </SimpleGrid>
  );
}
